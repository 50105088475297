import React, { useState, useEffect} from 'react';
import PhotoGallery from "./PhotoGallery";
import axios from 'axios';
import {Row, Col} from 'react-bootstrap';

const config = require('../../config.json');

const GalleryMain = (props) => {
  const [galleryImages, setGalleryImages]=useState([]);

  useEffect(() => { 
    fetchGalleryImages();
  }, []);

  const fetchGalleryImages =async()=>{
    try{
        const fileName='images/gallery/';
        const bucketName="images.aiache.co.in";
        await axios.get(`${config.api.invokeURL}/dashboard/websites/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
          headers: {
         // Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
       console.log("Response :", response.data);
       /*if(props.task==="Home"){
        const tempRes=response.data;
        tempRes.sort((a,b)=>new Date(b.projectDate)-new Date(a.projectDate));
       const slicedArray = tempRes.slice(0, 9);
        setGalleryImages(slicedArray);
       }else{
        setGalleryImages(response.data);
       }*/
        setGalleryImages(response.data);
       }).catch((error)=>{
        console.log("Error:",error.message);
       })
    }catch(error){
    console.log(error.message);
    }
    }
  

  /*const galleryData = [
    {
      galleryTitle: 'Nature Gallery',
      images: [
        '/images/nature1.jpg',
        '/images/nature2.jpg',
        '/images/nature3.jpg',
        '/images/nature4.jpg',
        '/images/nature5.jpg',
        '/images/nature1.jpg',
        '/images/nature2.jpg',
        '/images/nature3.jpg',
        '/images/nature4.jpg',
        '/images/nature5.jpg',

      ],
    },
    {
      galleryTitle: 'City Gallery',
      images: [
        '/images/city1.jpg',
        '/images/city2.jpg',
        '/images/city3.jpg',
        '/images/city4.jpg',
        '/images/city5.jpg',
      ],
    },
  ];*/

  return (
   <div className='container pt-3'>

   
       <div className='section-title' >
        <h2> Gallery </h2>
      </div>
          
    <div className="" style={{padding: "10px"}}>
      {/*galleryData.map((gallery, index) => (<div>
        <PhotoGallery key={index} galleryTitle={gallery.galleryTitle} images={gallery.images} />
        </div>
      ))*/}<Row>

     
      {galleryImages.map((gallery, index) => (<Col md={4} key={index} style={{paddingBottom:"10px"}}>
        <PhotoGallery key={index} galleryTitle={gallery.projectName} images={gallery.fileName} projectDate={gallery.projectDate} />
        </Col>
      ))} </Row>
    </div>
   </div>
  );
};

export default GalleryMain;
