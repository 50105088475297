import React from 'react'
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

const presidents = [
{ pic:require('../assets/img/presi/p1.jpg'),
  Name:"DR. P.T. CHANDY",
  Period:"(1967-1969)",
},
{ pic:require('../assets/img/presi/p2.jpg'),
    Name:"Fr. HERBERT D'SOUZA",
    Period:"(1970-1972)",
 },
{ pic:require('../assets/img/presi/p3.jpg'),
    Name:"DR. M.A. THANGARAJ",
    Period:"(1973-1975)",
},
{ pic:require('../assets/img/presi/p4.jpg'),
    Name:"FR.G.FRANCIS SJ",
    Period:"(1976-1978)",
},
{ pic:require('../assets/img/presi/p5.jpg'),
    Name:"Prof. Ninan Abraham",
    Period:"(1979-81)",
},
{ pic:require('../assets/img/presi/p6.jpg'),
    Name:"FR. J. KURIAKOSE",
    Period:"(1982-1984)",
},
{ pic:require('../assets/img/presi/p7.jpg'),
    Name:"Dr Mithra Augustine",
    Period:"(1985-1987)",
},
{ pic:require('../assets/img/presi/p8.jpg'),
    Name:"Fr. P.P. George SDB",
    Period:"(1988-1990)",
},
{ pic:require('../assets/img/presi/p9.jpg'),
    Name:"Dr.P.S. Jacob",
    Period:"(1991-1993)",
},
{ pic:require('../assets/img/presi/p10.jpg'),
    Name:"Fr. George Madathiparambil",
    Period:"(1994-1996)",
},
{ pic:require('../assets/img/presi/p11.jpg'),
    Name:"Dr. Peter Jayapandian",
    Period:"(1997-1999)",
},
{ pic:require('../assets/img/presi/p12.jpg'),
    Name:"Fr. Stephen Mavely",
    Period:"(2000-2002)",
},
{ pic:require('../assets/img/presi/p13.jpg'),
    Name:"Rev.(Prof.) Dr. R.B. Lal",
    Period:"(2003-2005)",
},
{ pic:require('../assets/img/presi/p14.jpg'),
    Name:"Prof. Newman Fernandes",
    Period:"(2006-2008)",
},
{ pic:require('../assets/img/presi/p13.jpg'),
    Name:"Rev.(Prof.) Dr. R.B. Lal",
    Period:"(2009-2011)",
},
{ pic:require('../assets/img/presi/p15.jpg'),
    Name:"Rev. Fr. Leslie Moras",
    Period:"(2012-2014)",
},
{ pic:require('../assets/img/presi/p16.jpg'),
    Name:"Dr. R.W. Alexander Jesudasan",
    Period:"(2015-2017)",
},
{ pic:require('../assets/img/presi/p17.jpg'),
    Name:"Rev. Dr. George Thadathil sdb",
    Period:"(2018-2022)",
},
{ pic:require('../assets/img/presi/p18.png'),
    Name:"Dr M Davamani Christober",
    Period:"(2022-2024)",
},
];
export default function Presidents() {
  return (
    <div className='container' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>Presidents - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Presidents - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Presidents - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/Presidents" />
    </Helmet>

         <div className='section-title'>
        <h2> Presidents </h2>
      </div>
    <Row>

      
    {presidents.map((item, index)=>
     <Col md={3}>
     <div className='p-2'>
    <Card className='founder-cardlayout' style={{ width: '18rem', padding:"5px" , height:"400px"}}>
      <Card.Img variant="top" src={item.pic} />
      <Card.Body>
        <Card.Title>{item.Name}</Card.Title>
        <Card.Text>
        {item.Des}
        </Card.Text>
        
      </Card.Body>
    </Card> </div>
    </Col>
  )}
    
    </Row>
  
   



    </div>
  )
}
