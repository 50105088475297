import React from 'react'
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

const secrataries = [
{ pic:require('../assets/img/secrat/g1.jpg'),
  Name:"FR. T.A. MATHIAS",
  Des:"General Secretary (1967-79)",
},
{ pic:require('../assets/img/secrat/g2.jpg'),
    Name:"Dr. Manuel A.Thangaraj",
    Des:"General Secretary (1979-81)",
  },
  { pic:require('../assets/img/secrat/g3.jpg'),
    Name:"Rev.Sr.Mary Braganza",
    Des:"General Secretary (1982-87)",
  },
  { pic:require('../assets/img/secrat/g4.jpg'),
    Name:"MRS. MARIE CORREA (BB)",
    Des:"General Secretary (1988-1990)",
  },
  { pic:require('../assets/img/secrat/g5.jpg'),
    Name:"DR. MANI JACOB",
    Des:"General Secretary (1991-2011)",
  },
  { pic:require('../assets/img/secrat/g6.jpg'),
    Name:"DR. D. DANIEL EZHILARASU",
    Des:"General Secretary (2011-2018)",
  },
  { pic:require('../assets/img/secrat/g7.jpg'),
    Name:"FR. JOSEPH PUTHENPURA CMI",
    Des:"General Secretary (2018-2020)",
  },
  { pic:require('../assets/img/secrat/g8.png'),
    Name:"FR. XAVIER VEDAM SJ",
    Des:"General Secretary (2020 - )",
  }
];
export default function GenSecrat() {
  return (
    <div className='container ' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>General Secrataries - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="General Secretaries - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="General Secretaries - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/Genral-Secrataries" />
    </Helmet>

      <div className='section-title'>
        <h2> General Secretaries </h2>
      </div>
      
   <Row>
  
    {secrataries.map((item, index)=>
     <Col md={3}>
        <div className='p-2'>
    <Card className='founder-cardlayout' style={{ width: '18rem', padding:"5px", height:"400px"}}>
      <Card.Img variant="top" src={item.pic} />
      <Card.Body>
        <Card.Title>{item.Name}</Card.Title>
        <Card.Text>
        {item.Des}
        </Card.Text>
        
      </Card.Body>
    </Card> </div>
    </Col>)}
  </Row>
   



    </div>
  )
}
