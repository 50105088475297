import React from 'react';
//import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
//import {Row, Col} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

export default function VissionMission() {
 
  return (
    <div className='container' style={{paddingTop:"8%"}} >
    <Helmet>
      <title>Vission & Mission - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Vission & Mission - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Vission & Mission - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/vission" />
    </Helmet>

      <div className='section-title'>
        
<h2 >Vission and Mission</h2>
      </div>
<Accordion defaultActiveKey="0">
      <Accordion.Item  eventKey="0" flush>
        <Accordion.Header className='acc-header'>Vission</Accordion.Header>
        <Accordion.Body >
         <p style={{color:"black", fontSize:"16px", textAlign:"justify", height:"400px", overflow:"auto"}}>The Christian service of education as a process of information for the formation and transformation, inspired by a vision of human 
        kind and the rest of the creation and its destiny drawn from the life and salvific mission of Jesus Christ. 
        The vision gives Christian institutions a recognizable character and sets before their managements, staff, students, parents and 
        the community, high ideals of life and service which will inspire them continuously to strive to meet emerging needs and challenges.
        Moved by this vision, the Christian Churches in India have been active in the field of education, 
        serving the nation in the context of its plurality of religions and diversity of cultures and languages. 
        The Christian colleges aim at an integral and personalized education of the youth. They strive to produce 
        intellectually competent, morally upright, socially committed, spiritually inspired citizens in the service of India 
        and the world of today and tomorrow.
        </p>
      
       
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='acc-header'>Mission</Accordion.Header>
        <Accordion.Body>
        <p style={{color:"black", fontSize:"16px", textAlign:"justify", height:"400px", overflow:"auto"}}>The Christian service of education as a process of information for the formation and transformation, inspired by a vision of human 
        AIACHE aims at the renewal and growth of member colleges as well as the promotion of the quality, value-orientation and social 
        relevance of Indian higher education in general. Over the years AIACHE has become the symbol of the deepening spirit of 
        ecumenism and Oneness in the Mission of the Churches, the new concept of mission understood as the responsibility to bring
         about Communion among the people, to work for Justice, Peace and Sustainable Development. A priority for AIACHE is 
         collaborative effort, working with the Governmental and Ecumenical agencies, people of other faiths and like-minded groups,
          national and international, with shared values and concerns.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='acc-header'>Purpose</Accordion.Header>
        <Accordion.Body>
        <p style={{color:"black", fontSize:"16px", textAlign:"justify", height:"400px", overflow:"auto"}}>The Christian service of education as a process of information for the formation and transformation, inspired by a vision of human 
        The purpose of AIACHE is to work for the constant development of educational specifications like knowledge and to create a 
        just and humane society in the light of Christian Faith, Gospel Values, Excellence and Leadership.
        </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header className='acc-header'>AIM</Accordion.Header>
        <Accordion.Body>
        <p style={{color:"black", fontSize:"16px", textAlign:"justify", height:"400px", overflow:"auto"}}>The Christian service of education as a process of information for the formation and transformation, inspired by a vision of human 
        The Aim of AIACHE is to bring all the Christian Higher Educational Institutions in India under one umbrella by name 
        “All India Association For Christian Higher Education”, to maintain it as an Ecumenical Association of Higher Education 
        and to encourage the Minorities of Christian Churches for their development upholding the Constitutional values of “Justice, 
        Liberty, Equality and, fraternity assuring the dignity of the individual and the unity and integrity of the nation” and 
        protecting the Minority Rights guaranteed by the Constitution.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  )
}
