import React from 'react';
import { Table } from 'react-bootstrap';

const members = [
  {
    "picture": require('../../assets/img/collegelogos/andhrachristianguntur.jpeg'),
    "LM_No": "LM1/ AP1",
    "state_category": "AP",
    "institute": "ANDHRA CHRISTIAN COLLEGE GUNTUR - 522 001 ANDHRA PRADESH",
    "website": "http://www.accollegeguntur.com/"
  },
  {
    "picture": require('../../assets/img/collegelogos/andhrachristianlawcollege.jpeg'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "ANDHRA CHRISTIAN COLLEGE OF LAW GUNTUR CITY - 522 001 ANDHRA PRADESH",
    "website": ""
  },
  {
    "picture": require('../../assets/img/collegelogos/alc.png'),
    "LM_No": "LM2/ AP3",
    "state_category": "AP",
    "institute": "ANDHRA LOYOLA COLLEGE VIJAYAWADA - 520 008 ANDHRA PRADESH",
    "website": "http://www.andhraloyolacollege.ac.in"
  },
  {
    "picture": require('../../assets/img/collegelogos/alce.png'),
    "LM_No": "LM3/ AP4",
    "state_category": "AP",
    "institute": "ANDHRA LUTHERAN COLLEGE OF EDUCATION P.B. NO. 228, BRODIEPET MAIN LINE GUNTUR - 522 002 ANDHRA PRADESH",
    "website": "http://www.alcollegeofeducation.org"
  },
  {
    "picture": require('../../assets/img/collegelogos/fatimacollegewarrangal.jpeg'),
    "LM_No": "LM4/ AP5",
    "state_category": "AP",
    "institute": "FATIMA COLLEGE OF EDUCATION FATIMA NAGAR POST R.E.C WARANGAL - 506 013 ANDHRA PRADESH",
    "website": "http://www.fatimacoewgl.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "HOLY CROSS COLLEGE NGUNADELA P.O. VIJAYAWADA - 520 004 ANDHRA PRADESH",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "JESUS MARY JOSEPH (J.M.J) DEGREE COLLEGE FOR WOMEN KARUNAPURAM, PEDDAPENDIAL WARANGAL - 506 151 ANDHRA PRADESH",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM5/ AP8",
    "state_category": "AP",
    "institute": "J.M.J. COLLEGE FOR WOMEN (AUTON.) MORRISPET, TENALI GUNTUR DISTRICT ANDHRA PRADESH - 522 202",
    "website": "http://www.jmjcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/collegelogos/loyolaacadamyalwal.png'),
    "LM_No": "LM6/ AP9",
    "state_category": "AP",
    "institute": "LOYOLA ACADEMY OLD ALWAL, SECUNDERABAD ANDHRA PRADESH - 522 010",
    "website": "http://www.loyolaacademyugpg.ac.in"
  },
  {
    "picture": require('../../assets/img/collegelogos/loyolayssr.jpeg'),
    "LM_No": "LM227/AP10",
    "state_category": "AP",
    "institute": "LOYOLA DEGREE COLLEGE (Y.S.R.R) PULIVENDLA - 516 390 CUDDAPAH DISTRICT ANDHRA PRADESH",
    "website": "https://loyoladegreecollegeysrr.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM7/ AP11",
    "state_category": "AP",
    "institute": "MARIS STELLA COLLEGE VIJAYAWADA - 520 008 ANDHRA PRADESH",
    "website": "http://www.marisstella.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "NOBLE COLLEGE MACHILIPATNAM - 521 001 ANDHRA PRADESH",
    "website": "http://noblecollegemtm.org/"
  },
  {
    "picture": require('../../assets/img/collegelogos/stannslogohyd.png'),
    "LM_No": "LM8/ AP13",
    "state_category": "AP",
    "institute": "ST. ANN`S COLLEGE FOR WOMEN SANTOSHNAGAR COLONY MEHDIPATNAM, HYDERABAD ANDHRA PRADESH - 500 028",
    "website": "http://www.stannscollegehyd.com"
  },
  {
    "picture": require('../../assets/img/collegelogos/stannssec.png'),
    "LM_No": "LM9/ AP14",
    "state_category": "AP",
    "institute": "ST. ANN`S COLLEGE OF EDUCATION SAROJINI DEVI ROAD SECUNDERABAD - 500 003 ANDHRA PRADESH",
    "website": "http://www.stannscoe.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "ST. ANN`S DEGREE COLLEGE MALKAPURAM VISAKHAPATNAM - 530 001 ANDHRA PRADESH",
    "website": "https://stannscollegevizag.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM10/ AP16",
    "state_category": "AP",
    "institute": "ST. FRANCIS COLLEGE FOR WOMEN BEGUMPET ROAD HYDERABAD - 500 016 ANDHRA PRADESH",
    "website": "http://www.sfc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "ST.GEORGE'S DEGREE COLLEGE 5-9-263, KING KOTI RD, ABIDS HYDERABAD, TELANGANA- 500001",
    "website": "https://www.stgdcw.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM11/ AP18",
    "state_category": "AP",
    "institute": "ST. JOSEPH`S COLLEGE OF EDUCATION FOR WOMEN SAMBASIVAPET, GUNTUR ANDHRA PRADESH - 522 004",
    "website": "http://www.stjosephbedcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM12/ AP19",
    "state_category": "AP",
    "institute": "ST. JOSEPH`S COLLEGE FOR WOMEN WALTAIR R.S. VISHAKAPATNAM - 530 004 ANDHRA PRADESH",
    "website": "https://stjosephsvizag.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM13/ AP20",
    "state_category": "AP",
    "institute": "ST. MARY`S CENTENARY COLLEGE OF EDUCATION GNANAPURAM, VISHAKAPATNAM ANDHRA PRADESH",
    "website": "http://smccevizag.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM14/ AP21",
    "state_category": "AP",
    "institute": "ST.PIOUS CHRISTIAN DEGREE AND P.G.COLLEGE FOR WOMEN SNEHAPURI COLONY NACHARAM HYDERABAD - 76 ANDHRA PRADESH",
    "website": "http://www.stpiouscollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM15/ AP22",
    "state_category": "AP",
    "institute": "CH.S.D ST. THERESA`S COLLEGE FOR WOMEN,ELURU GAVARAVARAM, ELURU W.G. DISTRICT ANDHRA PRADESH - 534 003",
    "website": "http://www.chsd-theresacollege.net"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "ST. VINCENT DE PAUL DEGREE COLLEGE, DE PAUL NAGAR PINAKADIMI VILLAGE WEST GODAVARI DISTRICT ANDHRA PRADESH - 534 003",
    "website": "https://www.depaulelr.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "STANLEY DEGREE COLLEGE FOR WOMEN, CHAPEL ROAD HYDERABAD - 500 001 ANDHRA PRADESH",
    "website": "http://stanley.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "S.T.B.C. DEGREE COLLEGE KURNOOL - 518 004 ANDHRA PRADESH",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "TARLUPAD COLLEGE OF EDUCATION MISSION COMPOUND TARLUPADU, PRAKASAM DISTRICT ANDHRA PRADESH - 523 332",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "VIDYA JYOTHI DEGREE & P.G. COLLEGE STATION GHANPUR WARANGAL - 506 144 TELANGANA",
    "website": "http://vidyajyothicollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM16/ AP28",
    "state_category": "AP",
    "institute": "VIJNANANILAYAM DEGREE COLLEGE JANAMPET, VIJAYARAI ELURU - 534 475 ANDHRA PRADESH",
    "website": "www.vijnananilayam.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "AP",
    "institute": "WESLEY DEGREE COLLEGE (CO-ED) 145, MCINTYRE ROAD SECUNDERABAD - 500 003 ANDRHA PRADESH ANDHRA PRADESH",
    "website": "https://wesleydegreecollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM226/AP32",
    "state_category": "AP",
    "institute": "ANDHRA LOYOLA INSTITUTE OF ENGINEERING AND TECHNOLOGY POLYTECHNIC POST OFFICE VIJAYAWADA - 520 008 ANDHRA PRADESH",
    "website": "https://www.aliet.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "BALDWIN WOMEN`S METHODIST COLLEGE, 90,RICHMOND ROAD BANGALORE - 560 025 KARNATAKA",
    "website": "https://www.baldwinwomensmethodistcollege.co.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM40/ KA 2",
    "state_category": "KA",
    "institute": "BISHOP COTTON WOMEN`S COLLEGE NO.9, III CROSS ROAD, C.S.I. COMPOUND MISSION ROAD, BANGALORE KARNATAKA - 560 027",
    "website": "http://www.bcwcc.org.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM41/ KA 3",
    "state_category": "KA",
    "institute": "VICE CHANCELLOR CHRIST (DEEMED TO BE UNIVERSITY) HOSUR ROAD, BANGALORE KARNATAKA - 560 029",
    "website": "http://www.christuniversity.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM42/ KA 4",
    "state_category": "KA",
    "institute": "CROSSLAND COLLEGE BRAHMAVAR KARNATAKA - 576 213",
    "website": "http://www.crosslandcollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "CSI COLLEGE OF COMMERCE DHARWAD - 580 001 KARNATAKA",
    "website": "http://www.csicc.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "DE PAUL FIRST GRADE COLLEGE AVVERABALLY, BELAGOLA SRIRANGAPATNA TALUK MANDYA DISTRICT MYSORE KARNATAKA - 571 606",
    "website": "https://depaulcollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "GOOD NEWS WELFARE SOCIETY ARTS AND COMMERCE FIRST GRADE COLLEGE KALGHATGI DHARWAD DIST KARNATAKA - 581 204",
    "website": "http://www.goodnewsdegreecollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM43/ KA8",
    "state_category": "KA",
    "institute": "JYOTI NIVAS COLLEGE HOSUR ROAD BANGALORE - 560 095 KARNATAKA",
    "website": "http://www.jyotinivas.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "KITTEL ARTS COLLEGE DHARWAD KARNATAKA - 580 001",
    "website": "https://kittelartscollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM44/ KA10",
    "state_category": "KA",
    "institute": "KITTEL SCIENCE COLLEGE DHARWAD KARNATAKA - 580 001",
    "website": "http://www.kittelsciencecollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM45/ KA11",
    "state_category": "KA",
    "institute": "MILAGRES COLLEGE KALLIANPUR, UDUPI DISTRICT KARNATAKA - 576 114",
    "website": "https://milagrescollegekallianpur.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM46/ KA12",
    "state_category": "KA",
    "institute": "MOUNT CARMEL COLLEGE 58, PALACE ROAD, BANGALOE KARNATAKA - 560 052",
    "website": "http://www.mccblr.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM47/ KA13",
    "state_category": "KA",
    "institute": "POMPEI COLLEGE AIKALA POST MANGALORE, D.K. KARNATAKA - 574 141",
    "website": "http://www.pompeicollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "SACRED HEART COLLEGE MADANTHYAR KARNATAKA - 574 224",
    "website": "http://www.sacredheartcollege.net"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM48/ KA15",
    "state_category": "KA",
    "institute": "SAINT CLARET COLLEGE P.O. BOX - 1355 M.E.S RING ROAD JALAHALLI BANGALORE - 560 013 KARNATAKA",
    "website": "http://www.claretcollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM49/ KA16",
    "state_category": "KA",
    "institute": "SCHOOL OF SOCIAL WORK ROSHNI NILAYA, MANGALORE KARNATAKA - 575 002",
    "website": "http://www.sswroshni.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM217/KA17",
    "state_category": "KA",
    "institute": "ST. AGNES COLLEGE BENDORE, MANGALORE KARNATAKA - 575 002",
    "website": "http://www.stagnescollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "ST. ALOYSIUS EVENING COLLEGE KODIYABAIL POST, MANGALORE KARNATAKA - 575 003",
    "website": "https://saec.co.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM50/ KA19",
    "state_category": "KA",
    "institute": "ST. ALOYSIUS COLLEGE (AUTONOMOUS) MANGALORE - 575 003 KARNATAKA",
    "website": "http://www.staloysius.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM51/KA20",
    "state_category": "KA",
    "institute": "ST. ANN`S COLLEGE OF EDUCATION MANGALORE - 575 001 KARNATAKA",
    "website": "http://www.stannscollege.net/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM52/ KA21",
    "state_category": "KA",
    "institute": "ST.ANNE`S FIRST GRADE COLLEGE FOR WOMEN #2, MILLERS ROAD BANGALORE - 560 052 KARNATAKA",
    "website": "https://www.stannesfgcmillerroad.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM53/ KA22",
    "state_category": "KA",
    "institute": "ST. JOHN`S MEDICAL COLLEGE BANGALORE - 560 034 KARNATAKA",
    "website": "http://www.stjohns.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM54/ KA23",
    "state_category": "KA",
    "institute": "ST. JOSEPH`S COLLEGE OF EDUCATION JAYALAKSHMIPURAM MYSORE - 570 012 KARNATAKA",
    "website": "http://www.stjcollegeofeducationmysuru.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM55/ KA24",
    "state_category": "KA",
    "institute": "ST. JOSEPH`S COLLEGE LALBAGH ROAD BANGALORE - 560 027 KARNATAKA",
    "website": "http://www.sjc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM56/ KA25",
    "state_category": "KA",
    "institute": "ST. JOSEPH`S COLLEGE OF COMMERCE 163, BRIGADE ROAD BANGALORE KARNATAKA - 560 001",
    "website": "http://www.sjcc.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM57/ KA26",
    "state_category": "KA",
    "institute": "ST. JOSEPH`S EVENING COLLEGE MUSEUM ROAD BANGALORE - 560 001 KARNATAKA",
    "website": "http://www.sjec.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM58/ KA27",
    "state_category": "KA",
    "institute": "ST. MARY`S COLLEGE SHIRVA P.O., UDUPI TALUK, D.K. KARNATAKA - 574 116",
    "website": "http://www.smcshirva.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM59/ KA28",
    "state_category": "KA",
    "institute": "ST. MARY`S SYRIAN COLLEGE BRAHMAVAR, UDUPI TALUK, D.K. KARNATAKA - 570 015",
    "website": "https://smscollege.org/en/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM60/ KA29",
    "state_category": "KA",
    "institute": "ST. PHILOMENA`S COLLEGE BANNIMANTAP, MYSORE KARNATAKA - 570 015",
    "website": "https://www.stphilos.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM61/ KA30",
    "state_category": "KA",
    "institute": "ST. PHILOMENA COLLEGE PHILONAGAR, DARBE P.O. PUTTUR KARNATAKA - 574 202",
    "website": "http://www.spcputtur.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM62/ KA31",
    "state_category": "KA",
    "institute": "TERESIAN COLLEGE MYSORE - 570 011 KARNATAKA",
    "website": "http://www.teresiancollege.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "UNITED MISSION DEGREE COLLEGE MISSION ROADS NEAR CITY CORPORATION BANGALORE - 560 027",
    "website": "http://www.umdcblr.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "BISHOP COTTON WOMEN`S CHRISTIAN- LAW COLEGE NO.19, IIIRD CROSS CSI COMPOUND BANGALORE - 560 027 KARNATAKA",
    "website": "https://www.bcwclc.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 240/KA34",
    "state_category": "KA",
    "institute": "ST VINCENT PALLOTTI COLLEGE CHELIKERE, KALYAN NAGAR BANGALORE - 560 043 KARNATAKA",
    "website": "https://svpcblr.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 241/KA 35",
    "state_category": "KA",
    "institute": "KRISTU JAYANTI COLLEGE (AUTONOMOUS) K. NAARAYANPURA, KOTHANUR P.O. BENGALURU - 560 077 KARNATAKA",
    "website": "https://kristujayanti.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 242/KA36",
    "state_category": "KA",
    "institute": "LOYOLA DEGREE COLLEGE SOS POST, KALENA AGRAHARA BG ROAD BANGALORE - 560 076",
    "website": "http://loyolacollegeblr.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM247/KA37",
    "state_category": "KA",
    "institute": "ST JOSEPH ENGINEERING COLLEGE, VAMANJOOR, MANGALORE 575028, KARNATAKA STATE",
    "website": "http://www.sjec.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KA",
    "institute": "MILAGRES COLLEGE, MANGALORE FALNIR ROAD, HAMPANKATTA MANGALORE, KARNATAKA - 575 001",
    "website": "http://www.milagrescollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM63/ KE 1",
    "state_category": "KL",
    "institute": "ALBERTIAN INSTITUTE OF SCIENCE AND TECHNOLOGY AISAT-TECHNICAL CAMPUS SCHOOL OF ENGINEERING ARCHBISHOP ANGEL MARY NAGAR COCHIN UNIVERSITY P.O. KALAMASSERY, KOCHI - 682 022 KERALA",
    "website": "http://www.aisat.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM64/ KE2",
    "state_category": "KL",
    "institute": "ALL SAINTS COLLEGE THIRUVANANTHAPURAM KERALA - 695 007",
    "website": "https://www.allsaintscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM65/ KE3",
    "state_category": "KL",
    "institute": "ALPHONSA COLLEGE PALAI, ARUNAPURAM P.O. KERALA - 686 574",
    "website": "http://www.alphonsacollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM66/ KE4",
    "state_category": "KL",
    "institute": "ASSUMPTION COLLEGE CHANGANACHERRY KERALA - 686 101",
    "website": "http://www.assumptioncollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "B.C.M. COLLEGE KOTTAYAM - 686 001 KERALA ,",
    "website": "http://wwwbcmcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM67/KE6",
    "state_category": "KL",
    "institute": "BISHOP KURIALACHERRY COLLEGE FOR WOMEN AMALAGIRI P.O. KOTTAYAM DISTRICT KERALA - 686 036",
    "website": "http://www.bkcollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM68/ KE7",
    "state_category": "KL",
    "institute": "BASELIUS COLLEGE KOTTAYAM - 686 001 KERALA",
    "website": "http://www.baselios.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM69/ KE8",
    "state_category": "KL",
    "institute": "BHARAT MATA COLLEGE THRIKAKARA P.O., COHIN KERALA - 682 021",
    "website": "https://bharatamatacollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "BISHOP MOORE COLLEGE KALLUMALA MAVELIKARA KERALA - 690 110",
    "website": "http://www.bishopmoorecollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM70/ KE10",
    "state_category": "KL",
    "institute": "C.M.S. COLLEGE KOTTAYAM - 686 001 KERALA",
    "website": "https://cmscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM71/ KE11",
    "state_category": "KL",
    "institute": "CARMEL COLLEGE MALA P.O., TRICHUR DISTRICT KERALA - 680 732",
    "website": "https://www.carmelcollegemala.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM72/ KE12",
    "state_category": "KL",
    "institute": "CATHOLICATE COLLEGE PATHANAMTHITTA KERALA - 689 645",
    "website": "https://www.catholicatecollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM73/ KE13",
    "state_category": "KL",
    "institute": "CHRISTIAN COLLEGE KATTAKADA, TRIVANDRUM KERALA - 695 572",
    "website": "https://www.christiancollegektda.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM74/ KE14",
    "state_category": "KL",
    "institute": "CHRIST COLLEGE IRINJALAKUDA, TRICHUR KERALA - 680 125",
    "website": "http://www.christcollegeijk.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM75/ KE15",
    "state_category": "KL",
    "institute": "CHRISTIAN COLLEGE ANGADICAL P.O. CHENGANNUR KERALA - 689 122",
    "website": "http://www.christiancollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM76/ KE16",
    "state_category": "KL",
    "institute": "DEVA MATHA COLLEGE KURAVILANGAD P.O. KERALA",
    "website": "https://www.devamatha.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM77/ KE17",
    "state_category": "KL",
    "institute": "FATIMA MATA NATIONAL COLLEGE KOLLAM - 691 001 KERALA",
    "website": "http://www.fatimacollege.net.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM78/ KE18",
    "state_category": "KL",
    "institute": "HENRY BAKER COLLEGE MELUKAVU, MELUKAVUMATTOM P.O. KOTTAYAM - 686 652 KERALA",
    "website": "https://www.henrybakercollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "KARMELA RANI TRAINING COLLEGE PATHANAMTHITTA, WEST KOLLAM KERALA - 691 001",
    "website": "http://wwwkarmelaranitrainingcollege.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM79/ KE20",
    "state_category": "KL",
    "institute": "KURIAKOSE ELIASE (K.E.) COLLEGE MANNANAM P.O., KOTTAYAM KERALA - 686 561",
    "website": "http://www.kecollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM212/KE21",
    "state_category": "KL",
    "institute": "KURIAKOSE GREGORIOS (K.G) COLLEGE PAMPADY, KOTTAYAM KERALA - 686 502",
    "website": "http://www.kgcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM80/ KE22",
    "state_category": "KL",
    "institute": "LITTLE FLOWER COLLEGE GURUVAYOOR P.O. PUTHAMPALLI THRISSUR DISTRICT KERALA - 680 103",
    "website": "https://littleflowercollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM81/ KE23",
    "state_category": "KL",
    "institute": "LOYOLA COLLEGE OF SOCIAL SCIENCES SREEKARIYAM P.O. THIRUVANANTHAPURAM KERALA - 695 017",
    "website": "https://loyolacollegekerala.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MAR ATHANASIUS COLLEGE FOR ADVANCED STUDEIS (MACFAST) TIRUVALLA KERALA - 689101",
    "website": "http://mac.macfast.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MALABAR CHRISTIAN COLLEGE CALICUT - 673 001 KERALA",
    "website": "https://mccclt.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MALANKARA ORTHODOX SYRIAN CHURCH MEDICAL COLLEGE KOLENCHERRY - 682 311 KERALA",
    "website": "https://www.moscmm.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM82/ KE27",
    "state_category": "KL",
    "institute": "MAR ATHANASIUS COLLEGE OF ARTS AND SCIENCE KOTHAMANGALAM, ERNAKULAM DIST. KERALA - 686 666",
    "website": "http://www.macollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM83/ KE28",
    "state_category": "KL",
    "institute": "MAR ATHANASIUS COLLEGE OF ENGINEERING KOTHAMANGALAM, ERNAKULAM KERALA - 686 666",
    "website": "http://www.mace.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MAR BASELIOS CHRISTIAN COLLEGE OF ENGINEERING & TECHNOLOGY PEERMADE - 685 381 IDUKKI DISTRICT, KERALA",
    "website": "http://www.mbcpeermade.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM84/ KE30",
    "state_category": "KL",
    "institute": "MAR IVANIOS COLLEGE BETHANY HILLS, NALANCHIRA TRIVANDRUM, KERALA - 695 015",
    "website": "http://www.mic.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM85/ KE31",
    "state_category": "KL",
    "institute": "MAR THEOPHILUS TRAINING COLLEGE NALANCHIRA, TRIVANDRUM KERALA - 695 015",
    "website": "https://www.mttc.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM86/ KE32",
    "state_category": "KL",
    "institute": "MAR THOMA COLLEGE CHUNGATHURA, MALAPURAM DIST. KERALA - 679 337",
    "website": "https://marthomacollegechungathara.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM87/ KE33",
    "state_category": "KL",
    "institute": "MAR THOMA COLLEGE KUTTAPUZHA P.O., TIRUVALLA KERALA - 689 103",
    "website": "http://marthomacollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM88/ KE34",
    "state_category": "KL",
    "institute": "MAR THOMA COLLEGE FOR WOMEN PERUMBAVOOR - 683 542 KERALA",
    "website": "https://marthomacollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MAR THOMA COLLEGE OF MANAGEMENT AND TECHNOLOGY ASRAMAM CAMPUS, PERUMBAVOOR KERALA - 683 542",
    "website": "https://www.mcmat.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM216/KE36",
    "state_category": "KL",
    "institute": "MARIAN COLLEGE KUTTIKANAM - 685 531 KERALA",
    "website": "http://www.mariancollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MARY MATHA ARTS & SCIENCE COLLEGE, VEMOM P.O MANANTHAVADY - 670 645 KERALA",
    "website": "http://marymathacollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM89/ KE39",
    "state_category": "KL",
    "institute": "MERCY COLLEGE PALAKKAD - 678 006 KERALA",
    "website": "https://www.mercycollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM90/ KE40",
    "state_category": "KL",
    "institute": "MORNING STAR HOME SCIENCE COLLEGE, ANGAMALY SOUTH KERALA - 683 573",
    "website": "http://www.morningstar.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM91/ KE41",
    "state_category": "KL",
    "institute": "MOUNT CARMEL TRAINING COLLEGE KOTTAYAM - 686 004 KERALA",
    "website": "http://www.mountcarmelcollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "MOUNT TABOR TRAINING COLLEGE PATHANAPURAM - 689 695 KERALA",
    "website": "https://www.mounttaborcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM92/ KE43",
    "state_category": "KL",
    "institute": "NEWMAN COLLEGE THODUPUZHA EAST P.O. KERALA - 685 585",
    "website": "https://newmancollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "NIRMALA COLLEGE OF PHARMACY MUVATTUPUZHA, ERNAKULAM DISTRICT KERALA - 686 661",
    "website": "http://www.nirmalacp.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM93/ KE45",
    "state_category": "KL",
    "institute": "NIRMALA COLLEGE MUVATTUPUZAH P.O. KERALA - 686",
    "website": "https://nirmalacollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM94/ KE46",
    "state_category": "KL",
    "institute": "NIRMALAGIRI COLLEGE NIRMALAGIRI P.O. TELLICHERRY, KANNUR DISTRICT KERALA - 670 701",
    "website": "https://nirmalagiricollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "P.K.M. COLLEGE OF EDUCATION MADAMPAM, KANNUR KERALA - 670 631",
    "website": "http://wwwpkmcedn.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "PAVANATMA COLLEGE MURICKASSERY IDUKKI DIST KERALA - 685 602",
    "website": "https://www.pavanatmacollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "PAZHASSI RAJA COLLEGE PULPALLY P.O. WAYNAD KERALA - 673579",
    "website": "https://www.pavanatmacollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM95/ KE50",
    "state_category": "KL",
    "institute": "PEET MEMORIAL TRAINING COLLEGE P.B. NO. 10, MAVELIKARA KERALA - 690 101",
    "website": "http://peetmemorialcollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM96/ KE51",
    "state_category": "KL",
    "institute": "PRAJYOTI NIKETAN COLLEGE PUDUKAD TRICHUR DISTRICT KERALA - 673 579",
    "website": "https://prajyotiniketan.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM97/ KE52",
    "state_category": "KL",
    "institute": "PROVIDENCE WOMEN`S COLLEGE MALAPARAMBU, CALICUT KERALA - 673 009",
    "website": "http://www.providencecollegecalicut.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM98/ KE53",
    "state_category": "KL",
    "institute": "RAJAGIRI COLLEGE OF SOCIAL SCIENCES RAJAGIRI P.O., KALAMASSERY KERALA - 683 104",
    "website": "http://www.rajagiri.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM99/ KE54",
    "state_category": "KL",
    "institute": "SACRED HEART COLLEGE CHALAKUDY - 680 307 KERALA",
    "website": "http://www.sacredheartcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM100/KE55",
    "state_category": "KL",
    "institute": "SACRED HEART COLLEGE THEVARA, ERNAKULAM, COCHIN KERALA - 682 013",
    "website": "https://www.shcollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM101/KE56",
    "state_category": "KL",
    "institute": "ST. ALOYSIUS COLLEGE EDATHUA, ALAPPUZHA DIST. KERALA - 689 573",
    "website": "https://aloysiuscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM102/KE57",
    "state_category": "KL",
    "institute": "ST. ALOYSIUS COLLEGE ELTHURUTH, TRICHUR DISTRICT KERALA - 680 611",
    "website": "https://aloysius.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. BERCHMAN`S COLLEGE CHANGANACHERRY KOTTAYAM DISTRICT KERALA - 686 101",
    "website": "https://sbcollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. CYRIL`S COLLEGE ADOOR, CYRIL`S MOUNT VADAKKADATHUCAVU P.O. PATHANAMTHITTA DIST. KERALA - 691 525",
    "website": "https://stcyrils.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM103/KE60",
    "state_category": "KL",
    "institute": "ST. DOMINIC`S COLLEGE KANJIRAPALLY, PARATHODU P.O. KERALA - 686 512",
    "website": "http://www.stdominiccollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM104/KE61",
    "state_category": "KL",
    "institute": "ST. GEORGE`S COLLEGE ARUVITHURA P.O. ERATTUPETTA, KOTTAYAM DIST. KERALA - 686 122",
    "website": "https://www.sgcaruvithura.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. GREGORIOS COLLEGE PULAMON P.O. KOTTARAKARA KERALA - 691 531",
    "website": "https://www.gregorioscollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM105/KE63",
    "state_category": "KL",
    "institute": "ST. JOHN`S COLLEGE ANCHAL P.O., KOLLAM DIST. KERALA - 691 306",
    "website": "http://www.stjohns.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM106/KE64",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S COLLEGE OF TR. EDUCATION ERNAKULAM, COCHIN KERALA - 682 035",
    "website": "http://www.stjosephcte.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM107/KE65",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S COLLEGE IRINJALAKUDA - 680 121 KERALA",
    "website": "http://www.stjosephs.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM108/KE66",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S COLLEGE DEVAGIRI, CALICUT KERALA - 673 008",
    "website": "http://www.devagiricollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM109/KE67",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S COLLEGE FOR WOMEN ALAPPUZHA - 688 001 KERALA",
    "website": "http://www.stjosephscollegeforwomen.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM110/KE68",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S TRAINING COLLEGE MANNANAM, KOTTAYAM KERALA - 686 561",
    "website": "http://www.stjosephstrainingcollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM111/KE69",
    "state_category": "KL",
    "institute": "ST. MARY`S COLLEGE SULTHAN BATHERY, WAYANAD KERALA - 673 592",
    "website": "http://stmarysbathery.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM112/KE70",
    "state_category": "KL",
    "institute": "ST. MARY`S COLLEGE THRISSUR - 680 020 KERALA",
    "website": "http://www.stmaryscollegethrissur.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM113/KE71",
    "state_category": "KL",
    "institute": "ST. MARY`S COLLEGE MANARCAD, KOTTAYAM DIST KERALA",
    "website": "http://www.stmaryscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM114/KE72",
    "state_category": "KL",
    "institute": "ST. MICHAEL`S COLLEGE MAYITHARA MARKET CHERTHALA KERALA - 688 539",
    "website": "https://www.stmc.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. PAUL`S COLLEGE KALAMASSERY - 683 503 KERALA",
    "website": "http://www.stpeterscollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM115/KE74",
    "state_category": "KL",
    "institute": "ST. PETER`S COLELGE KOLENCHERRY ERNAKULAM DIST KERALA - 682 311",
    "website": "https://www.stpeterscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. STEPHEN`S COLLEGE MALOOR COLLEGE P.O. PATHANAPURAM - 689 695 KERALA",
    "website": "https://ststephenspathanapuram.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. STEPHEN`S COLLEGE UZHAVOOR, KOTTAYAM DIST. KERALA - 686 634",
    "website": "http://wwwststephens.net.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM116/KE77",
    "state_category": "KL",
    "institute": "ST. TERESA`S COLLEGE ERNAKULAM, COCHIN KERALA - 682 011",
    "website": "http://www.teresas.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM117/KE78",
    "state_category": "KL",
    "institute": "ST. THOMAS COLLEGE THRISSUR - 680 001 KERALA",
    "website": "http://www.stthomas.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM /KE79",
    "state_category": "KL",
    "institute": "ST. THOMAS COLLEGE PALA, ARUNAPURAM P.O. KOTTAYAM DIST KERALA",
    "website": "https://www.stcp.ac.in/index.php"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM118/KE80",
    "state_category": "KL",
    "institute": "ST. THOMAS COLLEGE KOZHENCHERRY - 689 641 KERALA",
    "website": "https://www.stthomascollege.info/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. THOMAS COLLEGE PAZHAVANGADI P.O. RANNI KERALA - 689 673",
    "website": "https://www.stthomascollegeranni.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST.THOMAS COLLEGE OF TEACHER EDUCATION PALAI - 686 575 KERALA",
    "website": "http://www.stctepala.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. THOMAS COLLEGE OFTR. EDUCATION MYLACOMPU P.O. THODUPUZHA, IDUKKI DISTRICT KERALA",
    "website": "http://stthomascollegemylacompu.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM119/KE84",
    "state_category": "KL",
    "institute": "ST. XAVIER`S COLLEGE THUMBA, THIRUVANANTHAPURAM KERALA - 695 58",
    "website": "http://ww.stxaviersthumba.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM120/KE85",
    "state_category": "KL",
    "institute": "ST. XAVIER`S COLLEGE KOTHAVARA P.O. VAIKOM, KOTTAYAM DIST KERALA",
    "website": "http://www.stxavierscollegevaikom.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM121/KE86",
    "state_category": "KL",
    "institute": "ST. XAVIER`S COLLEGE FOR WOMEN ALUVA - 683 101 KERALA",
    "website": "http://www.stxaviersaluva.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "KL",
    "institute": "ST. JOSEPH`S COLLEGE MOOLAMATTOM, ARAKKULAM P.O. KERALA - 685 591",
    "website": "http://stjosephscollegemoolamattom.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM122/KE88",
    "state_category": "KL",
    "institute": "TITUS II TEACHERS` COLLEGE TIRUVALLA - 689 101 KERALA",
    "website": "http://tituscollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM123/KE89",
    "state_category": "KL",
    "institute": "UNION CHRISTIAN COLLEGE ALWAYE - 683 102 KERALA",
    "website": "https://uccollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM124/KE90",
    "state_category": "KL",
    "institute": "VIMALA COLLEGE TRICHUR - 680 009 KERALA",
    "website": "http://www.vimalacollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "POPE JOHN PAUL II COLLEGE OF EDUCATION REDDIARPALAYAM - 605 010 PONDICHERRY",
    "website": "https://www.popejohnpaulcollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ANANDA COLLEGE POST BOX NO.3 DEVAKOTTAI EXTN. - 630 303 TAMIL NADU",
    "website": "http://www.anandacollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM153/TN2",
    "state_category": "TN",
    "institute": "ANNAI VELANKANNI COLLEGE THOLAYAVATTAM P.O. KANYAKUMARI DISTRICT TAMIL NADU - 629 157",
    "website": "http://www.annaicollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM154/TN3",
    "state_category": "TN",
    "institute": "ARUL ANANDAR COLLEGE KARUMATHUR, MADURAI DIST TAMIL NADU - 626 514",
    "website": "http://www.aactni.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM155/TN4",
    "state_category": "TN",
    "institute": "AUXILIUM COLLEGE GANDHI NAGAR VELLORE TAMIL NADU - 632 006",
    "website": "http://www.auxiliumcollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "BISHOP AGNISWAMY COLLEGE OF EDUCATION, MUTTOM - 629 202 K.K.DIST TAMIL NADU",
    "website": "http://wwwbaceducation.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM156/TN6",
    "state_category": "TN",
    "institute": "BISHOP APPASAMY COLLEGE OF ARTS AND SCIENCE 204, RACE COURSE COIMBATORE - 641 018 TAMIL NADU",
    "website": "http://www.csibacas.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM157/TN7",
    "state_category": "TN",
    "institute": "BISHOP HEBER COLLEGE P.B. 615, TIRUCHIRAPALLI TAMIL NADU - 620 017",
    "website": "http://www.http://bhc.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM158/TN8",
    "state_category": "TN",
    "institute": "BISHOP THORP COLLEGE DHARAPURAM PERIYAR DIST TAMIL NADU - 638 657",
    "website": "http://www.bishopthorpcollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM159/TN9",
    "state_category": "TN",
    "institute": "BON SECOURS COLLEGE FOR WOMEN VILAR BYPASS ROAD THANJAVUR - 613 006 TAMIL NADU",
    "website": "http://www.bonsecourscollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "BON SECOURS COLLEGE OF EDUCATION VILAR BYPASS ROAD THANJAVUR - 613 006 TAMIL NADU",
    "website": "http://wwwbonsecourscollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CHILD JESUS COLLEGE OF NURSING CHILD JESUS HOSPITAL P.O.BOX NO.17, CANTONMENT TIRUCHIRAPALLI - 620 001 TAMIL NADU",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM160/TN12",
    "state_category": "TN",
    "institute": "CHRISTIAN MEDICAL COLLEGE THORAPADI P.O., BAGAYAM VELLORE - 632 002 TAMIL NADU",
    "website": "http://www.cmcvellore.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CENTRE FOR RESEARCH ON NEW INTERNATIONAL ECONOMIC ORDER (CRENIEO) VILLAGE NO. 36, VIA KOVALAM NEW MAHABALIPURAM ROAD MUTTUKADU - 603 112 CHENGALPETTU MGR DISTRICT TAMIL NADU",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CSI EWART WOMEN`S CHRISTIAN COLLEGE MELROSAPURAM - 603 204 TAMIL NADU",
    "website": "http://www.csiewartcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CSI COLLEGE OF ARTS AND SCIENCE FOR WOMEN, KARPAGAR NAGAR K.PODUR, MADURAI TAMIL NADU - 625 007",
    "website": "https://www.csiartscollege.co.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CSI COLLEGE OF DENTAL SCIENCES & RESEARCH CMH COMPOUND 129, EAST VELI STREET MADURAI - 625 001",
    "website": "http://csidentalcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CSI COLLEGE OF EDUCATION CSI INSTITUTIONAL CAMPUS PASUMALAI,MADURAI - 625 001 TAMIL NADU",
    "website": "http://www.csibedpasumalai.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "CSI COLLEGE OF EDUCATION CSI INSTITUTIONAL CAMPUS SINGARATHOPPU RAMANATHAPURAM - 1 TAMIL NADU",
    "website": "http://csibedramnad.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM161/TN19",
    "state_category": "TN",
    "institute": "CSI COLLEGE OF ENGINEERING KETTI THE NILGIRIS - 643 215 TAMIL NADU",
    "website": "http://www.csice.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM162/TN20",
    "state_category": "TN",
    "institute": "CSI JEYARAJ ANNAPACKIAM COLLEGE OF NURSING AND ALLIED SCIENCES MERRY DEW HILLS, JONESPURAM PASUMALI, MADURAI - 625 004 TAMIL NADU",
    "website": "http://www.csijacon.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ELIZABETH COLLEGE OF EDUCATION ANNAMANGALAM - 621 102 PERAMBALUR DISTRICT TAMIL NADU",
    "website": "https://www.elizabethcollege.in/b-ed-college/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM163/TN22",
    "state_category": "TN",
    "institute": "FATIMA COLLEGE MARY LAND, MADURAI - 625 018 TAMIL NADU",
    "website": "https://fatimacollegemdu.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "0",
    "state_category": "TN",
    "institute": "HINDUSTAN COLLEGE OF ARTS AND SCIENCE RAJIV GANDHI SALAI (OMR) PADUR KELAMBAKKAM CHENNAI - 603 103",
    "website": "http://www.hcaschennai.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM165/TN24",
    "state_category": "TN",
    "institute": "HOLY CROSS COLLEGE TEPPAKULAM, P.B. NO. 318 TIRUCHIRAPALLI - 620 002 TAMIL NADU",
    "website": "http://www.hcctrichy.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM166/TN25",
    "state_category": "TN",
    "institute": "HOLY CROSS COLLEGE ROCH NAGAR, NAGERCOIL TAMIL NADU - 629 004",
    "website": "https://holycrossngl.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "HOLY CROSS HOME SCIENCE COLLEGE 52, NEW COLONY, TUTICORIN TAMIL NADU - 682 003",
    "website": "http://www.hchsc.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "HOLY TRINITY COLLEGE OF EDUCATION MELPALAI EDAICODE P.O. K.K.DIST TAMIL NADU - 629 152",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM167/TN28",
    "state_category": "TN",
    "institute": "JAYARAJ ANNAPACKIAM COLLEGE FOR WOMEN MT. ST. ANNE, THAMARAIKULAM PERIYAKULAM, MADURAI DIST. TAMIL NADU - 626 562",
    "website": "http://www.annejac.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "KARUNYA INSTITUTE OF TECHNOLOGY & SCIENCES (DEEMED TO BE UNIVERSITY) KARUNYA NAGAR POST COIMBATORE - 641 114 TAMIL NADU",
    "website": "http://www.karunya.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM168/TN30",
    "state_category": "TN",
    "institute": "KAYPEEYES COLLEGE OF ARTS AND SCIENCE CORSLEY HILL, COONOOR ROAD KOTAGIRI - 643 217 TAMIL NADU",
    "website": "http://kaypeeyescollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM169/TN31",
    "state_category": "TN",
    "institute": "LADY DOAK COLLEGE MADURAI - 625 002 TAMIL NADU",
    "website": "http://www.ladydoakcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM170/TN32",
    "state_category": "TN",
    "institute": "LOYOLA COLLEGE (AUTONOMOUS) NUNGAMBAKKAM, CHENNAI TAMIL NADU - 600 034",
    "website": "http://www.loyolacollege.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "LOYOLA COLLEGE VETTAVALAM - 606 754 TIRUVANNAMALAI DIST TAMIL NADU",
    "website": "http://www.lcv.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "LOYOLA COLLEGE METTALA OILAPATTY (P.O) RASIPURAM (TK) NAMAKKAL DIST. TAMIL NADU",
    "website": "http://www.loyolacollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM171/TN35",
    "state_category": "TN",
    "institute": "LOYOLA COLLEGE OF EDUCATION NUNGAMBAKKAM CHENNAI - 600 034",
    "website": "https://www.loyolacollegeofeducation.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM236/TN36",
    "state_category": "TN",
    "institute": "LOYOLA - ICAM COLLEGE OF ENGINEERING & TECHNOLOGY LOYOLA CAMPUS NUNGAMBAKKAM CHENNAI - 600 034",
    "website": "http://www.licet.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM172/TN37",
    "state_category": "TN",
    "institute": "MADRAS CHRISTIAN COLLEGE TAMBARAM, CHENNAI TAMIL NADU - 600 059",
    "website": "http://www.mcc.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM173/TN38",
    "state_category": "TN",
    "institute": "MAR GREGORIOS COLLEGE BLOCK NO. 8 MOGAPPAIR WEST CHENNAI - 600 058 TAMIL NADU",
    "website": "http://www.margregorioscollege.net"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "MESTON TRAINING COLLEGE 33, WESTCOTT ROAD ROYAPETTAH - 600 014 TAMIL NADU",
    "website": "https://mestoncollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM219/TN40",
    "state_category": "TN",
    "institute": "NAZARETH GROUP OF INSTITUTIONS NAZARETH COLLEGE OF ARTS AND SCIENCE KOVILPATHANAGI MAIN ROAD AVADI CHENNAI",
    "website": "http://www.ncas.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "NAZARETH MARGOSCHIS COLLEGE PILLAYANMANAI CHIDAMBARANAR DIST TAMIL NADU - 628 617",
    "website": "http://www.margoschiscollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM174/TN42",
    "state_category": "TN",
    "institute": "NESAMONY MEMORIAL CHRISTIAN COLLEGE, MARTANDAM KANYAKUMARI DIST TAMIL NADU - 629 165",
    "website": "http://www.nmcc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM175/TN43",
    "state_category": "TN",
    "institute": "NIRMALA COLLEGE FOR WOMEN REDFIELDS, COIMBATORE TAMIL NADU - 641 018",
    "website": "http://www.nirmalacollegeonline.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM176/TN44",
    "state_category": "TN",
    "institute": "POPE`S COLLEGE SAWYERPURAM, CHIDAMBARANAR TAMIL NADU - 628 251",
    "website": "https://www.popescollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM177/TN45",
    "state_category": "TN",
    "institute": "PROVIDENCE COLLEGE SPRINGFIELDS,COONOOR TAMIL NADU - 643 104",
    "website": "http://www.providencecoonoor.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM178/TN46",
    "state_category": "TN",
    "institute": "SACRED HEART COLLEGE TIRUPATTUR, NORTH ARCOTT DIST TAMIL NADU - 635 601",
    "website": "http://www.shctpt.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "SARAH TUCKER COLLEGE TIRUNELVELI - 627 007 TAMIL NADU",
    "website": "https://sarahtuckercollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "S.B.T.T. TRAINING COLLEGE IN SPECIAL EDUCATION D.R.O. COLONY, MADURAI TAMIL NADU - 625 007",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM237/TN49 (new)",
    "state_category": "TN",
    "institute": "SCOTT CHRISTIAN COLLEGE NAGERCOIL - 629 003 TAMIL NADU",
    "website": "https://scott.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST.ANNE`S COLLEGE OF EDUCATION FOR WOMEN J.A.COLLEGE CAMPUS THAMARAIKULAM PERIYAKULAM THENI DIST TAMIL NADU - 625B 601",
    "website": "https://annesbedpkm.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM179/TN51",
    "state_category": "TN",
    "institute": "ST.CHRISTOPHER`S COLLEGE OF EDUCATION 63, E.V.K. SAMPATH ROAD VEPERY, CHENNAI - 600 007 TAMIL NADU",
    "website": "http://scced.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM180/TN52",
    "state_category": "TN",
    "institute": "ST. IGNATIUS COLLEGE OF EDUCATION PALAYAMKOTTAI, TIRUNELVELI TAMIL NADU - 627 002",
    "website": "http://www.ignatiuscollegeofeducation.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM231/TN53",
    "state_category": "TN",
    "institute": "ST. JOHN`S COLLEGE PALAYAMKOTTAI, TIRUNELVELI TAMIL NADU - 67 002",
    "website": "https://stjohnscollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST.JOHN`S COLLEGE OF ARTS AND SCIENCE THIVANDA KOTTAL ROAD AMMANDIVILAI KANYAKUMARI DIST TAMIL NADU",
    "website": "http://www.stjohnskk.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM181/TN55",
    "state_category": "TN",
    "institute": "ST.JOHN`S COLLEGE OF ENGINEERING NH 234, VELLORE- ARNI HIGH WAY NELVOY VELLORE - 632 011 TAMIL NADU",
    "website": "http://stjohnsinstitutions.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM182/TN56",
    "state_category": "TN",
    "institute": "ST.JOHN`S COLLEGE OF NURSING AND SCHOOL OF NURSING NH 234, VELLORE- ARNI HIGH WAY, NELVOY VELLORE - 632 011 TAMIL NADU",
    "website": "http://stjohnsinstitutions.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM183/TN57",
    "state_category": "TN",
    "institute": "ST. JOSEPH`S COLLEGE TRICHY, TEPPAKULAM P.O. TIRUCHIRAPPALI - 620 002 TAMIL NADU",
    "website": "http://www.sjctni.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST. JUSTIN`S COLLEGE OF EDUCATION 161, KAMARAJAR SALAI, MADURAI TAMIL NADU - 625 009",
    "website": "http://wwwjustinedn.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM184/TN59",
    "state_category": "TN",
    "institute": "ST. MARY`S COLLEGE NORTH BEACH ROAD, TUTICORIN TAMIL NADU - 628 001",
    "website": "http://stmaryscollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST.THOMAS COLLEGE OF EDUCATION VI STREET, KRISHNARAJAPURAM THOOTHUKUDI - 628 002 TAMIL NADU",
    "website": "https://stthomascollegeofedu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM185/TN61",
    "state_category": "TN",
    "institute": "ST. XAVIER`S COLLEGE PALAYAMKOTTAI, TIRUNELVELI DIST TAMIL NADU - 627 002",
    "website": "http://www.stxvierstn.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST. XAVIER`S COLLEGE OF EDUCATION (AUTONOMOUS) PALAYAMKOTTAI TIRUNELVELI DIST TAMI NADU - 627 002",
    "website": "http://sxcedn.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM186/TN63",
    "state_category": "TN",
    "institute": "STELLA MARIS COLLEGE 17, CATHEDRAL ROAD CHENNAI - 600 086, TAMIL NADU",
    "website": "http://www.stellamariscollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM187/TN64",
    "state_category": "TN",
    "institute": "STELLA MATUTINA COLLEGE OF EDUCATION, KAMRARAJ SALAI ASHOK NAGAR TAMIL NADU - 600 083",
    "website": "https://www.smce-chennai.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "ST. JUDE`S COLLEGE THOOTHOOR KANYAKUMARI DISTRICT TAMIL NADU",
    "website": "http://www.stjudescollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "TN",
    "institute": "TAMILNADU THEOLOGICAL SEMINARY ARASARADI, ELLIS NAGAR P.O. MADURAI - 625 010 TAMIL NADU",
    "website": "http://wwwtouchtelindia.net"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM188/TN67",
    "state_category": "TN",
    "institute": "TBML (TRANQUEBAR BISHOP MANIKAM LUTHERAN) COLLEGE, PORAYAR POST QUAID-E-MILLETH DIST TAMIL NADU - 609 307",
    "website": "http://tbmlcollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM189/TN68",
    "state_category": "TN",
    "institute": "THE AMERICAN COLLEGE TALLAKULAM POST MADURAI - 625 002 TAMIL NADU",
    "website": "http://www.americancollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM190/TN69",
    "state_category": "TN",
    "institute": "THE YMCA COLLEGE OF PHYSICAL EDUCATION, NO. 333, MOUNT ROAD NANDANAM, CHENNAI TAMI NADU - 600 035",
    "website": "http://www.ymcacollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM191/TN70",
    "state_category": "TN",
    "institute": "VOORHEES COLLEGE VELLORE -632 001 TAMIL NADU",
    "website": "https://voorheescollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM192/TN71",
    "state_category": "TN",
    "institute": "WOMEN`S CHRISTIAN COLLEGE COLLEGE ROAD, NUNGAMBAKKAM CHENNAI - 600 006 TAMIL NADU",
    "website": "http://www.wcc.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM193/TN72",
    "state_category": "TN",
    "institute": "WOMEN`S CHRISTIAN COLLEGE NAGERCOIL - 629 001 TAMIL NADU",
    "website": "https://www.wccnagercoil.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM214/TN 73",
    "state_category": "TN",
    "institute": "HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE (DEEMED TO BE UNIVERSITY) #1, RAJIV GANDHI SALAI(OMR) PADUR CHENNAI - 603 103 TAMIL NADU",
    "website": "http://www.hindustanuniv.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM238/TN74",
    "state_category": "TN",
    "institute": "REV. JACOB MEMORIAL CHRISTIAN COLLEGE SHANTHIPURAM AMBILIKKAI - 624 612 DINDIGUL DISTRICT TAMIL NADU",
    "website": "http://www.rjmchristiancollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM224/TN75",
    "state_category": "TN",
    "institute": "CSI BISHOP NEWBIGIN COLLEGE OF EDUCATION # 109, DR. RADHAKRISHNAN ROAD MYLAPORE, CHENNAI - 600 004 TAMIL NADU",
    "website": "http://bishopnewbigin.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM228/TN76",
    "state_category": "TN",
    "institute": "ST.CHARLES COLLEGE OF ARTS AND SCIENCE ERAIYUR-POST, KALLAKURICHI DT. 607 201 TAMIL NADU",
    "website": "http://www.stcharlescollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM232/TN77",
    "state_category": "TN",
    "institute": "DON BOSCO COLLEGE GUEZOU NAGAR, ATHANAVOOR, YELAGIRI HILLS TIRUPATTUR DISTRICT, TAMIL NADU - 635853",
    "website": "http://www.dbcyelagiri.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 243/TN78",
    "state_category": "TN",
    "institute": "BON SECOURS ARTS AND SCIENCE COLLEGE FOR WOMEN, RUCKMANIPALAYAM,MANNARGUDI THIRUVARUR - 614 001 TAMIL NADU",
    "website": "https://arts-science-college.business.site/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 244/TN79",
    "state_category": "TN",
    "institute": "ST JOSEPH`S ARTS AND SCIENCE COLLEGE FOR WOMEN NATTARMANGALAM ROAD SINGAMPUNARI - 630 501 SIVAGANGAI DISTRCT TAMIL NADU",
    "website": "https://stjosephwc.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 245/TN80",
    "state_category": "TN",
    "institute": "ST. ANTONY`S COLLEGE OF ARTS & SCIENCES FOR WOMEN AMALA ANNAI NAGAR THAMARAIPADI, DINDIDGUL - 624 005 TAMIL NADU",
    "website": "https://sacw.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 246/TN81",
    "state_category": "TN",
    "institute": "GONZAGA COLLEGE OF ARTS AND SCIENCE FOR WOMEN, KATHAMPALLAM, ELATHAGIRI - 635 108, KRISHNAGIRI DISTRICT, TAMIL NADU",
    "website": "https://gonzagacollege.business.site/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 247/TN82 ",
    "state_category": "TN",
    "institute": "St. Anne's College of Engineering and Technology, Anguchettypalayam, Taluk, Post, Siruvathur, Panruti- 607110",
    "website": "https://www.stannescet.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 248/TN83 ",
    "state_category": "TN",
    "institute": "Morning Star Arts and Science College for Women, Pasumpon  Kamuthi, Ramnad, Tamil Nadu",
    "website": "https://morningstar.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM25/DL1",
    "state_category": "NR",
    "institute": "JESUS AND MARY COLLEGE CHANAKYAPURI NEW DELHI - 110 021",
    "website": "http://www.jmc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM215/DL2",
    "state_category": "NR",
    "institute": "ST. STEPHEN`S COLLEGE DELHI UNIVERSITY ENCLAVE DELHI - 110 007",
    "website": "http://www.ststephens.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM31/ HP 1",
    "state_category": "NR",
    "institute": "ST. BEDE`S COLLEGE SHIMLA - 171 002 HIMACHAL PRADESH",
    "website": "https://www.stbedescollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "BHOPAL SCHOOL OF SOCIAL SCIENCES, NEAR DRM OFFICE HABIB GANJ BHOPAL - 462 024 MADHYA PRADESH",
    "website": "http://wwwbsssbhopal.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM125/MP2",
    "state_category": "NR",
    "institute": "DANIELSON DEGREE COLLEGE CHHINDWARA MADHYA PRADESH - 480 001",
    "website": "http://www.danielsoncollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "HAWABAGH WOMEN`S COLLEGE NARBADA ROAD, JABALPUR MADHYA PRDESH",
    "website": "https://hawabaghcollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "INDORE SCHOOL OF SOCIAL WORK 14, OLD SEHORE ROAD INDORE MADHYA PRADESH - 452 001",
    "website": "http://wwwisswindore.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "INDORE CHRISTIAN COLLEGE 1, MURAI MOHALLA, INDORE MADHYA PRADESH - 452 001",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM126/MP6",
    "state_category": "NR",
    "institute": "ST. ALOYSIUS COLLEGE (AUTONOMOUS) 1, AHILYA MARG, SADAR, CANTT., JABALPUR - 482 001 MADHYA PRADESH",
    "website": "http://www.aloysiuscollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM127/MP7",
    "state_category": "NR",
    "institute": "ST. PAUL INSTIUTE OF PROFESSIONAL STUDIES, INDORE NEAR ST. PAUL HIGHER SECONDARY SCHOOL LALARAM NAGAR ROAD INDORE MADHYA PRADESH",
    "website": "http://www.spipsindore.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM128/MP8",
    "state_category": "NR",
    "institute": "XAVIER INSTITUTE OF DEVELOPMENT ACTION AND STUDIES (XIDAS) MANDLA ROAD, TILHARI JABALPUR - 482 021 MADHYA PRADESH",
    "website": "http://www.ximj.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "ST. ALOYSIUS INSTITUTE OF TECHNOLOGY NEAR EKTA MARKET GAUR BRIDGE MANDLA ROAD JABALPUR, M.P. 482 001",
    "website": "https://saitjbp.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM233/NE 1",
    "state_category": "NR",
    "institute": "ST. XAVIER`S COLLEGE MAITIGHAR, POST BOX 7437 KATHMANDU, NEPAL (FR JOSEPH VICTOR PICHAI SJ PROVINCE TREASURER & PROVINCE DEVELOPMENT DIRECTOR DARJEELING JESUIT PROVINCE MATIGARA, DARJEELING DIST WEST BENGAL - 734010)",
    "website": "http://www.sxc.edu.np"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM149/PU1",
    "state_category": "NR",
    "institute": "BARING UNION CHRISTIAN COLLEGE BATALA, GURDASPUR PUNJAB - 143 505",
    "website": "http://www.buccbatala.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM150/PU2",
    "state_category": "NR",
    "institute": "CHRISTIAN MEDICAL COLLEGE LUDHIANA - 141 008 PUNJAB",
    "website": "http://www.cmcludhiana.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM194/UP1",
    "state_category": "NR",
    "institute": "CHRIST CHURCH COLLEGE THE MALL, KANPUR UTTAR PRADESH",
    "website": "http://www.cccknp.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM195/UP2",
    "state_category": "NR",
    "institute": "EWING CHRISTIAN COLLEGE ALLAHABAD - 211 003 UTTAR PRADESH",
    "website": "http://www.ecc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM196/UP3",
    "state_category": "NR",
    "institute": "ISABELLA THOBURN COLLEGE LUCKNOW - 226 007 UTTAR PRADESH",
    "website": "https://itcollege.ac.in/itdc/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM220/UP4",
    "state_category": "NR",
    "institute": "LUCKNOW CHRISTIAN COLLEGE GOLAGANJ, LUCKNOW - 226 018 UTTAR PRADESH",
    "website": "http://www.lcdc.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM197 /UP5",
    "state_category": "NR",
    "institute": "SAM HIGGINBOTTOM UNIVERSITY OF AGRICULTURE, TECHNOLOGIES & SCIENCE (SHUATS) P.O. ALLAHABAD AGRICULTURAL INSTITUTE, NAINI, ALLAHABAD UTTAR PRADESH - 211 007",
    "website": "http://www.shuats.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM198/UP6",
    "state_category": "NR",
    "institute": "ST. ANDREW`S COLLEGE GORAKHPUR - 273 001 UTTAR PRADESH",
    "website": "http://www.st-andrews-college.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM199/UP7",
    "state_category": "NR",
    "institute": "ST. JOHN`S COLLEGE AGRA - 282 002 UTTAR PRADESH",
    "website": "https://stjohnscollegeagra.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "ST. JOSEPH`S COLLEGE FOR WOMEN CIVIL LINES, UNIVERSITY P.O. GORAKHPUR - 273 009 UTTAR PRADESH",
    "website": "http://wwwstjosephcollegegkp.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "ST. JOSEPH`S GIRLS DEGREE COLLEGE SARDANHA P.O., MEERUT DIST UTTAR PRADESH",
    "website": "http://www.stjosephscollegesardhana.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "WILLIAM HOLLAND UNIVERSITY COLLEGE HOLLAND HALL ALLAHABAD UTTAR PRADESH",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NR",
    "institute": "INSTITUTE OF TECHNOLOGY ROORKEE (ITR), WOMEN`S ENGINEERING COLLEGE RCP UNIVERSE, 09 MILESTONE ROORKEE DEHRADUN HIGHWAY KISHANPUR, ROORKEE - 247 667",
    "website": "http://www.itrroorkee.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM213/UK2",
    "state_category": "NR",
    "institute": "ROORKEE COLLEGE OF MANAGEMENT AND COMPUTER APPLIANCES RCP UNIVERSE, 09 MILESTONE ROORKEE DEHRADUN HIGHWAY KISHANPUR, ROORKEE - 247 667",
    "website": "https://rcmcaedu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM19/ BI 1",
    "state_category": "ER",
    "institute": "PATNA WOMEN`S COLLEGE BAILEY ROAD G.P.O. PATNA BIHAR - 834 001",
    "website": "http://www.patnawomenscollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM20/ BI 2",
    "state_category": "ER",
    "institute": "ST. XAVIER`S COLLEGE OF EDUCATION DIGHA GHAT P.O. PATNA BIHAR - 800 011",
    "website": "http://www.sxcepatna.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM21/ BI 3",
    "state_category": "ER",
    "institute": "ST. XAVIER'S COLLEGE OF MANAGEMENT & TECH. PATNA DIGHA GHAT P.O. PATNA - 800 011 BIHAR",
    "website": "http://www.sxcpatna.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "BETHANY COLLEGE OF NURSING BORSI, DURG CHHATISGARH - 491001",
    "website": "https://www.bcn-bhilai.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "CHRIST COLLEGE JAGDALPUR - 494 001 BASTAR DIST CHHATISGARH",
    "website": "http://www.christjagdalpur.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "CHRISTIAN COLLEGE OF ENGINEERING & TECHNOLOGY, KAILASH NAGAR P.O. BOX 18, INDUSTRIAL ESTATE BHILAI, CHHATISGARGH - 490 026",
    "website": "https://ccetbhilai.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM22/ CH 4",
    "state_category": "ER",
    "institute": "HOLY CROSS COLLEGE OF NURSING HOLY CROSS HOSPITAL AMBIKAPUR - 497 001 SURGUJA DISTRICT CHHATISGARH",
    "website": "http://www.holycrossnursingcollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM23/CH5",
    "state_category": "ER",
    "institute": "HOLY CROSS WOMEN`S COLLEGE AMBIKAPUR SURGUJA DISTRICT CHHATISGARH - 497 001",
    "website": "http://www.holycrosswcamb.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM24/CH6",
    "state_category": "ER",
    "institute": "LOYOLA COLLEGE KUNKURI DIST JASHPUR CHHATISGARH",
    "website": "http://www.lck.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "ST. THOMAS COLLEGE VIGYAN NAGAR, RUABANDA SECTOR, BHILAI, DURG CHHATISGARH",
    "website": "https://www.stthomascollegebhilai.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 239/CH8",
    "state_category": "ER",
    "institute": "ST.XAVIER`S COLLEGE OF EDUCATION AMBIKAPUR P.O.FUNDURDIHARI AMBIKAPUR, SURGUJA DIST CHHATISGARH",
    "website": "http://xaviereducation.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM32/ JH 1",
    "state_category": "ER",
    "institute": "GOSSNER COLLEGE G.E.L. CHURCH COMPOUND MAIN ROAD, RANCHI JHARKHAND - 834 001",
    "website": "http://www.gcran.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM33/ JH 2",
    "state_category": "ER",
    "institute": "LOYOLA COLLEGE OF EDUCATION RIVER VIEW AREA TELCO, JAMSHEDPUR JHARKHAND - 831 004",
    "website": "https://www.loyolacollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM34/ JH 3",
    "state_category": "ER",
    "institute": "NIRMALA COLLEGE HINOO P.O. RANCHI - 834 002 JHARKHAND",
    "website": "http://www.nirmalacollegeranchi.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "PARAMVIR ALBERT EKKA MEMORIAL COLLEGE CHAINPUR, DIST. GUMLA JHARKHAND - 835 206",
    "website": "https://pvaem.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM35/ JH5",
    "state_category": "ER",
    "institute": "ST. AUGUSTINE`S COLLEGE P.O. MANOHARPUR DIST.WEST SINGHBHUM JHARKHAND - 833 104",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM36/ JH6",
    "state_category": "ER",
    "institute": "ST. JOSEPH`S COLLEGE P.O. TORPA, DIST RANCHI JHARKHAND - 835 227",
    "website": "https://stjct.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM37/ JH 7",
    "state_category": "ER",
    "institute": "ST. XAVIER`S COLLEGE RANCHI - 834 001 JHARKHAND",
    "website": "http://www.sxcran.org.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM38/ JH8",
    "state_category": "ER",
    "institute": "URSULINE WOMEN`S TEACHERS` TRAINING COLLEGE LOHARDAGA P.O. JHARKHAND - 835 302",
    "website": "http://uwttcollege.org.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM39/ JH9",
    "state_category": "ER",
    "institute": "XAVIER LABOUR RELATIONS INSTITUTE (XLRI) C.H. AREA (EAST) JAMSHEDPUR, JHARKHAND",
    "website": "http://www.xlri.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "ER",
    "institute": "ODISSA",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM147/OD1",
    "state_category": "ER",
    "institute": "CHRIST COLLEGE CUTTACK - 753 008 ORISSA",
    "website": "http://www.christcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM148/OD2",
    "state_category": "ER",
    "institute": "STEWART SCIENCE COLLEGE MADHUSUDAN ROAD BUXI BAZAR, CUTTACK ORISSA - 753 001",
    "website": "http://www.stewartsciencecollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM234/OD3",
    "state_category": "ER",
    "institute": "XIM UNIERSITY PLOT NO. 12 (A) NIJIGADA KURKI HARIRAJPUR - 752 050 DIST. PURI ORISSA",
    "website": "http://www.xub.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM200/WB1",
    "state_category": "ER",
    "institute": "BANKURA CHRISTIAN COLLEGE BANKURA - 722 101 WEST BENGAL",
    "website": "http://www.bankurachristiancollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM201/WB2",
    "state_category": "ER",
    "institute": "LORETO COLLEGE 7, MIDDLETON ROW CALCUTTA - 700 071 WEST BENGAL",
    "website": "http://www.loretocollege.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM202/WB3",
    "state_category": "ER",
    "institute": "NORTH BENGAL ST .XAVIER`S COLLEGE RAJGANJ P.O. DIST JALPAIGURI NORTH BENGAL - 735 134",
    "website": "http://www.nbxc.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM203/WB4",
    "state_category": "ER",
    "institute": "SALESIAN COLLEGE SONADA &SILIGURI 734209 & 734001 WEST BENGAL",
    "website": "http://salesiancollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM204/WB5",
    "state_category": "ER",
    "institute": "SCOTTISH CHURCH COLLEGE 1 & 2 URQUHARI SQUARE CALCUTTA - 700 006 WEST BENGAL",
    "website": "http://www.scottishchurch.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM205/WB6",
    "state_category": "ER",
    "institute": "SERAMPORE COLLEGE SERAMPORE 9, WILLIAM CAREY ROAD HOOGHLY DIST WEST BENGAL - 712 201",
    "website": "https://www.seramporecollege.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM206/WB7",
    "state_category": "ER",
    "institute": "ST. JOSEPH`S COLLEGE P.O. NORTH POINT DIST DARJEELING WEST BENGAL - 734 104",
    "website": "http://www.sjcdarjeeling.co.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM207/WB8",
    "state_category": "ER",
    "institute": "ST. PAUL`S CATHEDRAL MISSION COLLEGE 33/1, RAJA RAMMOHAN SARANI CALCUTTA - 700 009 WEST BENGAL",
    "website": "http://www.spcmc.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM208/WB9",
    "state_category": "ER",
    "institute": "ST. XAVIER`S COLLEGE 30, PARK STREET CALCUTTA - 700 016 WEST BENGAL",
    "website": "http://www.sxccal.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM209/WB10",
    "state_category": "ER",
    "institute": "UNION CHRISTIAN TRAINING COLLEGE BERHAMPORE, MURSHIDABAD WEST BENGAL - 742 101",
    "website": "http://www.uctcollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM210/WB11",
    "state_category": "ER",
    "institute": "WOMEN`S CHRISTIAN COLLEGE 6, GREE CHURCH ROW KALIGHAT, CALCUTTA - 700 026 WEST BENGAL",
    "website": "https://womenschristiancollege.net/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 230/WB12",
    "state_category": "ER",
    "institute": "CLUNY WOMEN'S COLLEGE 8TH MILE, P.O. KALIMPONG PIN CODE - 734 301 WEST BENGAL",
    "website": "http://www.clunycollege.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 235/WB13",
    "state_category": "ER",
    "institute": "ST.XAVIER'S UNIVERSITY, KOLKATA ACTION AREA III B, NEW TOWN KOLKATA - 700160 WEST BENGAL",
    "website": "https://sxuk.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM 249/WB14 ",
    "state_category": "ER",
    "institute": "St. Xavier's College (Affiliated to The University of Burdwan), St. Xavier's Road, Kanainatshal, P.O - Sripalli, Burdwan 713103, W.B",
    "website": "www.sxcb.edu.in "
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM17/ AR1",
    "state_category": "NE",
    "institute": "DON BOSCO COLLEGE ITANAGAR JULLY, P.O. ITANAGAR - 791 111 ARUNACHAL PRADESH",
    "website": "https://dbcitanagar.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM18/ AR2",
    "state_category": "NE",
    "institute": "SAINT CLARET COLLEGE SALAYA, ZIRO LOWER SUBANSIRI DISTRICT ARUNACHAL PRADESH",
    "website": "https://sccz.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "SAINT FRANCIS DE SALES (S.F.S) COLLEGE, AALO P.B.NO. 34, WEST SIANG DIST. ARUNACHAL PRADESH - 791 001",
    "website": "http://www.sfscaalo.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM223/MA1",
    "state_category": "NE",
    "institute": "DON BOSCO COLLEGE (AUTONOMOUS) MARAM, SENAPATI DISTRICT P.O. MARAM BAZAR MANIPUR - 795 015",
    "website": "http://www.dbcmaram.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "DON BOSCO COLLEGE TURA MEGHALAYA",
    "website": "http://wwwdbctura.net"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "ST. ANTHONY`S COLLEGE SHILLONG - 793 001 MEGHALAYA",
    "website": "http://www.anthonys.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "ST. EDMUND`S COLLEGE SHILLONG - 793 003 MEGHALAYA",
    "website": "http://wwwsec.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM142/ME4",
    "state_category": "NE",
    "institute": "ST. MARY`S COLLEGE SHILLONG - 793 003 MEGHALAYA",
    "website": "https://smcs.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM143/ME5",
    "state_category": "NE",
    "institute": "SYNOD COLLEGE TEFGAN BUNGALOW SHILLONG MEGHALAYA - 793 002",
    "website": "https://www.synodcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM144/ME6",
    "state_category": "NE",
    "institute": "UNION CHRISTIAN COLLEGE BARAPANI, RI-BHOI DIST MEGHALAYA - 793 122",
    "website": "https://www.uccollegemeghalaya.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "BAPTIST COLLEGE MISSION COMPOUND KOHIMA - 797 001, NAGALAND",
    "website": "https://www.baptistcollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM145/NA2",
    "state_category": "NE",
    "institute": "PATKAI CHRISTIAN COLLEGE B.P.O. PATKAI, CHUMUKEDIMA SEITHKIEMA - 797 103 NAGALAND",
    "website": "http://www.patkaicollege.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM146/NA3",
    "state_category": "NE",
    "institute": "ST. JOSEPH`S COLLEGE P.B. 39, KOHIMA, JAKHAMA NAGALAND - 797 001",
    "website": "http://www.stjosephjakhama.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM221/NA4",
    "state_category": "NE",
    "institute": "ST.JOSEPH UNIVERSITY VIRGIN TOWN, IKISHE MODEL VILLAGE DIMAPUR NAGALAND - 797 115",
    "website": "http://www.stjosephuniv.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "SIKKIM",
    "website": ""
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "NE",
    "institute": "LOYOLA COLLEGE OF EDUCATION NAMCHI SIKKIM - 737 126",
    "website": "http://wwwloyolasikkim.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM152/TR1",
    "state_category": "NE",
    "institute": "HOLY CROSS COLLEGE DURJOYNAGAR P.O. AIR PORT ROAD AGARTALA - 799 009 WEST TRIPURA",
    "website": "https://www.holycrosscollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM26/GA1",
    "state_category": "WR",
    "institute": "CARMEL COLLEGE OF ARTS, SCIENCE AND COMMERCE FOR WOMEN NUVEM, SALCETTE GOA - 403 604",
    "website": "https://www.carmelcollegegoa.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "WR",
    "institute": "DON BOSCO COLLEGE PANJIM,GOA",
    "website": "https://donboscogoa.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM225/GA3",
    "state_category": "WR",
    "institute": "FR. AGNEL COLLEGE OF ARTS AND COMMERCE PILAR - 403 203 GOA",
    "website": "http://www.fragnelcollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM27/ GA4",
    "state_category": "WR",
    "institute": "NIRMALA INSTITUTE OF EDUCATION ALTINHO, PANAJI GOA - 403 001",
    "website": "http://www.nirmalainstitute.org/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM28/ GA5",
    "state_category": "WR",
    "institute": "ROSARY COLLEGE OF COMMERCE AND ARTS, NAVELIM, SALCETTE GOA - 403 707",
    "website": "http://www.rosarycollege.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM29/ GA6",
    "state_category": "WR",
    "institute": "ST. XAVIER`S COLLEGE XAVIER NAGAR MAPUSA, BARDEZ GOA - 403 507",
    "website": "http://www.xavierscollege-goa.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM211/GA7",
    "state_category": "WR",
    "institute": "ST.JOSEPH VAZ COLLEGE CHURCH STREET CORTALIM GOA - 403 710",
    "website": "https://stjosephvazcollege.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM30/GU1",
    "state_category": "WR",
    "institute": "ST. XAVIER`S COLLEGE NAVRANGPURA, AHMEDABAD GUJARAT - 380 009",
    "website": "http://www.sxca.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM129/MR1",
    "state_category": "WR",
    "institute": "AHMEDNAGAR COLLEGE P.B. NO. 21, STATION ROAD AHMEDNAGAR - 414 001 MAHRASHTRA",
    "website": "http://www.aca.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM130/MR2",
    "state_category": "WR",
    "institute": "COLLEGE OF HOME SCIENCE NIRMALA NIKETAN 49, NEW MARINES LINES MUMBAI - 400 020, MAHARASHTRA",
    "website": "http://www.nirmalaniketan.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM131/MR3",
    "state_category": "WR",
    "institute": "COLLEGE OF SOCIAL WORK NIRMALA NIKETAN 38, NEW MARINE LINES MUMBAI - 400 020 MAHARASHTRA",
    "website": "https://cswnn.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM132/MR4",
    "state_category": "WR",
    "institute": "HISLOP COLLEGE TEMPLE ROAD, CIVIL LINES NAGPUR - 400 001, MAHARASHTRA",
    "website": "http://www.hislopcollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM133/MR5",
    "state_category": "WR",
    "institute": "SOPHIA COLLEGE FOR WOMEN BHULABHAI DESAI ROAD MUMBAI - 400 026 MAHARASHTRA",
    "website": "http://www.sophiacollegemumbai.com"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM134/MR6",
    "state_category": "WR",
    "institute": "SPICER ADVENTIST UNIVERSITY AUNDH ROAD, GANESHKIND PUNE - 411 007, MAHARASHTRA",
    "website": "http://www.sau.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM135/MR7",
    "state_category": "WR",
    "institute": "ST. ANDREW`S COLLEGE OF ARTS, SCIENCE AND COMMERCE ST. DOMINIC ROAD, BANDRA MUMBAI - 400 050 MAHARASHTRA",
    "website": "http://www.standrewscollege.ac.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM136/MR8",
    "state_category": "WR",
    "institute": "ST. FRANCIS DE SALES COLLEGE SEMINARY HILLS NAGPUR - 440 006 MAHARASHTRA",
    "website": "https://sfscollege.edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM137/MR9",
    "state_category": "WR",
    "institute": "ST. GONSALO GARCIA COLLEGE OF ARTS AND COMMERCE PAPDY-VASAI, THANE DIST MAHARASHTRA - 401 207 .",
    "website": "https://www.ggcollege.ac.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "WR",
    "institute": "ST. TERESA`S INSTITUTE OF EDUCATION, S.V. ROAD SANTA CRUZ (W) MUMBAI - 400 054",
    "website": "https://www.sti-edu.in/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM138/MR11",
    "state_category": "WR",
    "institute": "ST. VINCENT COLLEGE OF COMMERCE 2004, ST. VINCENT STREET PUNE - 411, MAHARASHTRA",
    "website": "http://svcpune.com/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM139/MR12",
    "state_category": "WR",
    "institute": "ST. XAVIER`S COLLEGE 5, MAHAPALIKA MARG MUMBAI - 400 001 MAHARASHTRA",
    "website": "http://www.xaviers.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "",
    "state_category": "WR",
    "institute": "ST. XAVIER`S INSTITUTE 40-A, NEW MARINE LINES CHURCHGATE, MUMBAI - 400 020 MAHARASHTRA",
    "website": "https://ww2.sxie.info/"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM218/MR14",
    "state_category": "WR",
    "institute": "WILSON COLLEGE CHAUPATTY, MUMBAI - 400 007 MAHARASHTRA",
    "website": "http://www.wilsoncollege.edu"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM140/MR15",
    "state_category": "WR",
    "institute": "ST.FRANCIS INSTITUTE OF MANAGEMENT & RESEARCH, MUMBAI MT.POINSUR SVP ROAD, BORIVLI (w) MUMBAI - 400 103 MAHARASHTRA",
    "website": "http://www.sfimar.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM222/MH16",
    "state_category": "WR",
    "institute": "CHRIST COLLEGE, 26/4A, SAINIKWADI VADGAON SHERI PUNE - 411 014 MAHARASHTRA",
    "website": "http://www.christcollegepune.org"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM229/MR26",
    "state_category": "WR",
    "institute": "ST PAUL`S INSTITUTE OF COMMUNICATION EDUCATION FOR WOMEN ST PAUL`S MEDIA COMPLEX 24TH ROAD, TPS III BANDRA (WEST) MUMBAI - 400 050 MAHARASHTRA",
    "website": "http://www.stpaulscollege.edu.in"
  },
  {
    "picture": require('../../assets/img/image.png'),
    "LM_No": "LM151/RA1",
    "state_category": "WR",
    "institute": "SOPHIA GIRLS` COLLEGE (AUTONOMOUS) MEERSHAH ALI COLONY JAIPUR ROAD, AJMER RAJASTHAN - 305 001",
    "website": "http://www.sophiacollegeajmer.in"
  }
 ]


export default function SearchTableView(props) {
    const {handleShow} = props;

  return (
    <div className='p-4'>
        {members.length>0 ? <Table striped bordered responsive hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Logo</th>
          <th>Life Memership No</th>
          <th>State Category</th>
          <th>Name of the Institute</th>
          <th>Website</th>
         
        </tr>
      </thead>
      <tbody>
      {members.map((item,index)=>{return(
        <tr onClick={event=>handleShow(event, item)}>
          <td>{index+1}</td>
          <td><img className='img-fluid' src={item.picture} alt="" /></td>
          <td>{item.LM_No}</td>
          <td>{item.state_category}</td>
          <td>{item.institute}</td>
          <td>{item.website}</td>
          
        </tr>
      )})}
      </tbody>
      </Table>:null}
    </div>
  )
}
