import React from 'react';
import { Link } from "react-router-dom";
import logo from './assets/img/frontlogo.png';
import { NavbarToggle, Collapse } from 'react-bootstrap';
import FlashNews from './pages/FlashNews';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import FlashNewsMarque from './components/flashNews/FlashNewsMarque';

export default function NavbarView() {
  
  //const [collapsed, setCollapsed] = useState(true);
  //const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <header id="header" className="header fixed-top">
        <div className="topbar d-none d-md-flex align-items-center">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center mainView" >
        {/*} <b> <p className='text-white pt-3 ' style={{textDecoration:"underline"}}>Contact Us</p></b> */}
          <i className="bi bi-phone d-flex align-items-center ms-4"><span> +91-9444761101</span></i>
         {/*<b><p className='text-white m-2 'style={{textDecoration:"underline"}}>Get in Touch</p> </b> */}
         <i className="p-2 bi bi-envelope align-items-center text-white">
          <a style={{textDecoration:"none"}} className="text-white" href="mailto:contact@example.com"> aiache2011@gmail.com</a></i>
          </div>
         {/*} <p style={{fontSize:"15px", paddingTop:"1.2%", paddingLeft:"15%"}} className='head-title text-white mainView'>ALL INDIA ASSOCIATION FOR CHRISTIAN HIGHER EDUCATION</p>*/}
   
        <div className="social-links d-none d-md-flex align-items-center">
       
         
          <a href="#" className="twitter"><i className="bi bi-twitter-x"></i></a>
          <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
        </div>
       
      </div>
    </div>
    
        <div>
  {/*}
    <div className="container position-relative d-flex justify-content-around">
        
      
          
    <div > 
          <h1 className='d-flex justify-content-start head-title' ><img className='aiache-logo' src={logo} alt=""/> </h1>
       
       </div>
       
   
        
        
        

        <div  className='d-flex justify-content-end '>
       
         <nav  id="navmenu" className="navbar  navmenu">
         
        <ul>
              <li className="nav-item">
                <Link to="/" ><a className="nav-link" href="#"> Home</a></Link>
              </li>
              <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>About</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    <Link to="/about" ><a className="dropdown-item p-2" href="#">About Us</a></Link>
                    <Link to="/action" ><a className="dropdown-item p-2" href="#">Action Plan</a></Link>
                    <Link to="/vission" ><a className="dropdown-item p-2" href="#">Vission & Mission</a></Link>
                </div>
            </li>
            <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>Our Team</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    <Link to="/Executive"><a className="dropdown-item p-2" href="#">Executive Board</a></Link>
                    <Link to="/Office-Bearers"><a className="dropdown-item p-2" href="#">Office Bearers</a></Link>
                    <Link to="/Founders"><a className="dropdown-item p-2" href="#">The Founding Leaders</a></Link>
                    <Link to="/Presidents"><a className="dropdown-item p-2" href="#">Presidents</a></Link>
                    <Link to="/General-secrataries"><a className="dropdown-item p-2" href="#">General Secrataries</a></Link>
                </div>
            </li>
              <li className="nav-item">
                <Link to="/members"><a className="nav-link" href="#"> Members</a></Link>
              </li>
              <li className="nav-item">
                <Link to="/gallery"><a className="nav-link" href="#"> Gallery</a></Link>
              </li>
              <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>Journals</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    <Link to="/frontiers"><a className="dropdown-item p-2" href="#">New Frontier Education</a></Link>
                    <Link to="/vikasini"><a className="dropdown-item p-2" href="#">Vikasini</a></Link>
                    <Link to="/vikasini"><a className="dropdown-item p-2" href="#">Editorial Team</a></Link>
                </div>
            </li>
              <li className="nav-item">
                <Link to="/asia"><a className="nav-link" href="#"> ASIANetwork</a></Link>
              </li>
              <li className="nav-item">
                <Link to="/application"><a className="nav-link" href="#"> Applications</a></Link>
              </li>
        
        
       
        </ul>
        <i onClick={toggleNavbar} className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>
        </div>
      

    </div>
*/}
  </div>
  <Navbar expand="lg" className="navbar  navmenu">
      <Container>
        <Navbar.Brand href="#home"> <img className='aiache-logo' src={logo} alt=""/> 
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link  href="/" style={{color:"white", cursor:"pointer"}}> Home</Nav.Link>
            <Nav.Link href="#"><li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>About</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    <Link to="/about" ><a className="dropdown-item p-2" href="#">About Us</a></Link>
                    <Link to="/vission" ><a className="dropdown-item p-2" href="#">Vission & Mission</a></Link>
                </div>
            </li></Nav.Link>

            <Nav.Link href="#"> <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>Our Team</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    
                    <Link to="/executiveboard"><a className="dropdown-item p-2" href="#">Executive Board</a></Link>
                    <Link to="/Founders"><a className="dropdown-item p-2" href="#">The Founding Leaders</a></Link>
                    <Link to="/Presidents"><a className="dropdown-item p-2" href="#">Presidents</a></Link>
                    <Link to="/General-secrataries"><a className="dropdown-item p-2" href="#">General Secrataries</a></Link>
                </div>
            </li></Nav.Link>
            <Nav.Link href="/members" style={{color:"white", cursor:"pointer"}}>  Members
            </Nav.Link>
            <Nav.Link href="#"> <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>Journals</a>
                <div className="dropdown-menu p-2" style={{left:"auto"}}>
                    <Link to="/frontiers"><a className="dropdown-item p-2" href="#">New Frontier Education</a></Link>
                    <Link to="/vikasini"><a className="dropdown-item p-2" href="#">Editorial Team</a></Link>
                </div>
            </li></Nav.Link>
            <Nav.Link href="/asia" style={{color:"white", cursor:"pointer"}}>ASIANetwork</Nav.Link>
            <Nav.Link href="/application" style={{color:"white", cursor:"pointer"}}>Applications</Nav.Link>
            <Nav.Link href="/galleryphoto" style={{color:"white", cursor:"pointer"}}>Gallery</Nav.Link>
             
             
             
           
        
        
       
  
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  
  <div  style={{backgroundColor:"#f6f885", width:"100%", height:"50px"}}>
        
        <FlashNewsMarque />
          </div> 
    </header>
    
  )
}
