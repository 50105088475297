import flash from '../assets/img/upcoming.png';
import React, {useEffect, useState} from 'react';
import axios from 'axios';

const config = require('../config.json');

export default function FlashNews() {
  const [activePost, setActivePost]=useState([]);

  useEffect(() => {
    fetchActiveInfo();
},[]);

const generateDate = async () => {
const current_datetime = new Date();
const date_year = (current_datetime.getFullYear()).toString();
var date_month = (current_datetime.getMonth() + 1).toString();
var month_value="";
date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
var date_date = (current_datetime.getDate().toString());
var date_value="";
date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
var date_hours = (current_datetime.getHours().toString());
var hours_value="";
date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
var date_minutes = (current_datetime.getMinutes().toString());
var minutes_value="";
date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
var todayDate="";
const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
//setUserId(accountNo);
return tDate;
//console.log("acc : ", accountNo);
}    

const fetchActiveInfo =async()=>{
try{
   const startDate=await generateDate();
   //console.log("start Date : ", startDate);
   const clientID="AIACHE";
   const searchOption="Flash News";
await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
   headers: {
    // Authorization: access_token,
     'x-api-key': config.api.key
   }}, 
).then((response)=>{
   //console.log(response);
   if(response.data.length>0){
     setActivePost(response.data);
   }
}).catch((error)=>{
})
}catch(error){
}
}


  return (
    <div style={{display:'flex'}}>
        <div style={{display:'inline-block'}}><img style={{width:"100px"}} src={flash} alt='' /></div>
        <marquee><div className="p-2" style={{fontSize:"22px", color:"red"}}><b><i className="fas fa-bullhorn me-2 "></i> TRIENNIAL CONFERENCE: 27 - 29 DEC in Bhuvaneshwar -  REGISTRATION LINK: <a href="https://docs.google.com/forms/d/e/1FAIpQLSeNUDzCIGWp0R0Ev5bUsEDPRiL5azrtk6CviriFZB9ZTfdTtA/viewform"> Click here... </a></b></div></marquee>
    </div>
  )
}
