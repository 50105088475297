import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import blog1 from '../assets/img/blog/blog-1.jpg';
import blog2 from '../assets/img/blog/blog-2.jpg';
import blog3 from '../assets/img/blog/blog-3.jpg';

export default class GalleryView extends Component {
  render() {
    return (
        <section id="recent-posts" className="recent-posts section">
      <Helmet>
      <title>Gallery - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Gallery - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Gallery - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/gallery" />
    </Helmet>

 
        <div className="container section-title" data-aos="fade-up">
          <h2>GALLERY</h2>
          <p></p>
        </div> 
  
        <div className="container">
  
          <div className="row gy-4">
  
            <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
              <article>
  
                <div class="post-img">
                  <img src={blog1} alt="" class="img-fluid" />
                </div>
  
                <p className="post-category"></p>
  
                <h2 className="title">
                  <a href="blog-details.html">NATIONAL ASSEMBLY</a>
                </h2>
  
                <div className="align-items-center">
                 
                  <div className="post-meta">
                    <p className="post-author text-center">Date: 28-12-2013</p>
                 <p className="post-date">
                      <time datetime="2022-01-01">Photos: 5</time>
                    </p> 
                  </div>
                </div>
  
              </article>
            </div>
  
            <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
              <article>
  
                <div class="post-img">
                  <img src={blog2} alt="" class="img-fluid"/>
                </div>
  
                <p className="post-category"></p>
  
                <h2 className="title">
                  <a href="blog-details.html">AIACHE PROGRAMMES</a>
                </h2>
  
                <div class="align-items-center">
                
                  <div class="post-meta">
                    <p class="post-author">Date: 18-05-2019</p>
                    <p class="post-date">
                      <time datetime="2022-01-01">Photos: 4</time>
                    </p>
                  </div>
                </div>
  
              </article>
            </div> 
  
            <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
              <article>
  
                <div class="post-img">
                  <img src={blog3} alt="" class="img-fluid"/>
                </div>
  
                <p className="post-category"></p>
  
                <h2 className="title">
                  <a href="blog-details.html">SEMINAR: BASICS OF ACCREDITATION</a>
                </h2>
  
                <div class="align-items-center">
                   <div class="post-meta">
                    <p class="post-author">Date: 30-11-2019</p>
                    <p class="post-date">
                      <time datetime="2022-01-01">Photos: 12</time>
                    </p>
                  </div>
                </div>
  
              </article>
            </div> 
  
          </div>
  
        </div>
  
      </section>
    )
  }
}
