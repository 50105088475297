import React, {useEffect, useState} from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
//import Card from 'react-bootstrap/Card';
//import Button from 'react-bootstrap/Button';
import axios from 'axios';

import {Row, Col} from 'react-bootstrap';
/*import Image from "../assets/img/frameRecent.jpg"
import slide1 from '../assets/img/slider/s1.jpg';
import slide2 from '../assets/img/slider/s2.jpg';
import slide3 from '../assets/img/slider/s3.jpg';
import slide4 from '../assets/img/slider/s4.jpg';
import slide5 from '../assets/img/slider/s5.jpg';
import slide6 from '../assets/img/slider/s6.jpg';
import slide7 from '../assets/img/slider/s7.jpg';
import slide8 from '../assets/img/slider/s8.jpg';
import slide9 from '../assets/img/slider/s9.jpg';
import slide10 from '../assets/img/slider/s10.jpg';
import slide11 from '../assets/img/slider/s11.jpg';
import slide12 from '../assets/img/slider/s12.jpg';
import slide13 from '../assets/img/slider/s13.jpg';
import slide14 from '../assets/img/slider/s14.jpg';*/
import '../App.css';
import Preloader from '../components/Preloader/Preloader';
//import MarqueeView from './MarqueeView';
//import Carousel from 'react-bootstrap/Carousel';
//import Marquee1 from './testMarquee/Marquee1';


const config = require('../config.json');

/*const items = [
    <img className='img-fluid' src={slide1} alt='slider1' />,
    <img className='img-fluid' src={slide2} alt='slider2' />,
    <img className='img-fluid' src={slide3} alt='slider3' />,
    <img className='img-fluid' src={slide4} alt='slider4' />,
    <img className='img-fluid' src={slide5} alt='slider5' />,
    <img className='img-fluid' src={slide6} alt='slider6' />,
    <img className='img-fluid' src={slide7} alt='slider7' />, 
    <img className='img-fluid' src={slide8} alt='slider8' />,
    <img className='img-fluid' src={slide9} alt='slider9' />,
    <img className='img-fluid' src={slide10} alt='slider10' />,
    <img className='img-fluid' src={slide11} alt='slider11' />,
    <img className='img-fluid' src={slide12} alt='slider12' />,
    <img className='img-fluid' src={slide13} alt='slider13' />,
    <img className='img-fluid' src={slide14} alt='slider14' />
  ];*/

  /*const news = [
    { pic:require('../assets/img/tri.jpg'),
      Name:"FR. T.A. MATHIAS",
      Des:"General Secretary (1967-79)"
    },
    {
      pic:require('../assets/img/slider/s1.jpg'),
      Name:"FR. T.A. MATHIAS",
      Des:"General Secretary (1967-79)"
    },
    {
      pic:require('../assets/img/tri.jpg'),
      Name:"FR. sdfasdf",
      Des:"asdf"
    }
  ]*/
const imgProps={
    0: {
        items: 1,
        itemsFit: 'fill',
    },
    300: {
      items: 1,
      itemsFit:'contain'
    },
    1024: {
        items: 2,
        itemsFit: 'contain',
    }
  };

  
 

export default function CaroselMultiView() {   
  const [galleryImages, setGalleryImages] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchGalleryImages();
}, [])

const fetchGalleryImages =async()=>{
    try{
        setLoaded(false);
        //const session = await Auth.currentSession();
        //const access_token=session.idToken.jwtToken;
      //const fileName='images/gallery/';
     // const bucketName="images.stjosephhospitaldindigul.in";
  //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
    //const fileName='images/doctor/';
    const fileName='images/slider';
    const bucketName="images.aiache.co.in";
    await axios.get(`${config.api.invokeURL}/dashboard/websites/slider?fileName=${fileName}&bucketName=${bucketName}`,{
      headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Response : ", response);
        const finalList=[];
        const finalList1=[];
        if(response.data.length>0){
          for (const i in response.data){
            const tValue=response.data[i];
            const tPathName=tValue.split("/");
            const tBreakUp=tPathName[2];
            const tempValue={};
            tempValue.fullPathName=response.data[i];
            tempValue.fileName=tBreakUp;
           // const tdoctorName=tBreakUp[1];
           // const tempDName=tdoctorName.split(".");
           // tempValue.doctorName=tempDName[0];
           // console.log("tempvalue : ", tempValue);
           // tempValue.projectSize=tBreakUp[2];
            //tempValue.projectDate=tBreakUp[3];
            const temp=<img src={`${config.s3.ImageinvokeURL}${response.data[i]}`} 
            onDragStart={handleDragStart} role='presentation' key={i} alt={tBreakUp} style={{padding:"5px", borderRadius:"20px", width:"100%", maxHeight:"400px"}}/>
            finalList1.push(temp);
            finalList.push(tempValue);
          }
        }      
        //console.log("list : ", finalList1);

        //console.log("items : ", items);
        setGalleryImages(finalList1);
        /*setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message:response.data.length + " - Images are found in Slider section"
        }); */

        //setDispMsg(response.data.length + " - Images are found in your Gallery");
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        setLoaded(true);
        window.scrollTo(0,0); 
    })
}catch(error){
    setLoaded(true);
    window.scrollTo(0,0); 
}
setLoaded(true);
}

const handleDragStart = (e) => e.preventDefault();


  return (
    <div >
          <Preloader show={loaded ? false : true} />

      <Row>
        <Col md={12} >
       
        <AliceCarousel mouseTracking items={galleryImages}  
                    autoPlay infinite 
                    animationDuration={3000}
                    animationType="fadeout"
                    autoPlayDirection="rtl"
                    disableButtonsControls={true}
                    disableDotsControls={true}
                    responsive={imgProps}   
                                 
                    /> 
        
        </Col>
      {/*  <Col md={3}  >       
{/*}       <div style={{alignItems:"center", backgroundImage: 'url(' + Image + ')', backgroundRepeat:"no-repeat", backgroundSize: 'contain', height:"100%" }}>*/}
    {/*    <Marquee1/>
       
       
        </Col> */}
      </Row>
        
    </div>
  )
}
