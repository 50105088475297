import React from 'react'
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

const executives = [
{ pic:require('../assets/img/offbearers/7.jpg'),
  Name:"Rev. Sr. M. Rashmi A.C.",
  Des:"President",
},
{ pic:require('../assets/img/offbearers/10.jpg'),
    Name:"Rev. Dr. Praveen Martis, SJ",
    Des:"Vice-President",
  },
  { pic:require('../assets/img/offbearers/16.jpg'),
    Name:"Dr. Paul Wilson",
    Des:"Vice-President",
  },
  
  { pic:require('../assets/img/secrat/g8.png'),
    Name:"FR. XAVIER VEDAM SJ",
    Des:"General Secretary (2020 - )",
  }
];
export default function ExecutiveMembers() {
  return (
    <div className='container ' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>Executive Members - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Executive Members - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Executive Members - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/Executive" />
    </Helmet>

      <div className='section-title'>
        <h2> Executive Members </h2>
      </div>
      
   <Row>
  
    {executives.map((item, index)=>
     <Col md={3}>
        <div className='p-2'>
    <Card className='founder-cardlayout' style={{ width: '18rem', padding:"5px", height:"350px"}}>
      <Card.Img style={{height:"250px"}} className='img-fluid' variant="top" src={item.pic} />
      <Card.Body>
        <Card.Title>{item.Name}</Card.Title>
        <Card.Text>
        {item.Des}
        </Card.Text>
        
      </Card.Body>
    </Card> </div>
    </Col>)}
  </Row>
   



    </div>
  )
}
