import React, {useState}  from 'react';
import SearchCardView from './SearchCardView';
import SearchTableView from './SearchTableView';
import { Helmet } from 'react-helmet-async';

export default function MembersView() {
    const [tableView,setTableView] = useState(false);
    const [cardView, setCardView] = useState(true);
    const [show, setShow] = useState(false);
    const handleShow = (event, item) => {
        event.preventDefault();
        
        setShow(true);
      }
    const handleTableView=(event)=>{
        event.preventDefault();
        setTableView(true)
        setCardView(false);
      }
  
      const handleCardView=(event)=>{
        event.preventDefault();
        setTableView(false);
        setCardView(true);
      }
  return (
    <div className="container">
    <Helmet>
      <title>Members - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Members - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Members - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/members" />
    </Helmet>


<div >
        <div className="heading_container pt-3">
        <div className='section-title'>
        <h2> Members List </h2>
      </div>
        </div>
        {/*  <form action="#" className='card p-2'>
          <div className="row">
          <div className="col-md-3">
            <select name="searchOption" id="category"
           >
                  <option value="region">Regions</option>
                  <option value="lm-no">Life Membership</option>
                  <option value="lm-no">Institute Name</option>
            </select>            
          </div>
         
         
            <div className="col-md-7 ">
                <input type="text" placeholder="" />
            </div>
               
            <div className="col-md-2 text-center">
              <button className='btn-box' >
                Search
              </button>
            </div>  
            </div>
          </form>*/}
    </div>
    <div className="row ml-4"  >
    <div className="col-md-6 ">
   
    </div>
          <div className="col-md-6 btn-group" style={{textAlign:"end", alignItems:"flex-end"}}>
            <div style={{marginLeft:"95%", marginRight:"-10%"}}>
         <a href="#" className= {tableView?'btn btn-success':'btn btn-default'}><i onClick={event=>handleTableView(event)} className="fa fa-table fa-lg" aria-hidden="true" style={{fontSize:"20px"}}></i></a>
          <a href="#" className= {cardView?'btn btn-success':'btn btn-default'}><i onClick={event=>handleCardView(event)} className="fa fa-id-card fa-lg" aria-hidden="true" style={{fontSize:"20px"}}></i></a>
          </div>
          </div>
        </div>

        {tableView?<SearchTableView  handleShow={handleShow}/>:null}
        {cardView? <SearchCardView  handleShow={handleShow}/>:null}
    </div>
  )
}
