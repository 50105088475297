import React from 'react';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

const founders = [
{ pic:require('../assets/img/founders/f1.jpg'),
  Name:"FR. T.A. MATHIAS",
  Des:"General Secretary (1967-79)",
},
{ pic:require('../assets/img/founders/f2.jpg'),
    Name:"Dr Panavelil Thomas Chandy",
    Des:"(1967-69)",
  },
  { pic:require('../assets/img/founders/f3.jpg'),
    Name:"Dr Chandran Devanesan",
    Des:"(Co-Founder)",
  },
  { pic:require('../assets/img/founders/f4.jpg'),
    Name:"Fr. HERBERT D'SOUZA",
    Des:"(1970-1972)",
  },
  { pic:require('../assets/img/founders/f5.jpg'),
    Name:"Archbishop Angelo Fernandes",
    Des:"Co Founder",
  },
  { pic:require('../assets/img/founders/f6.jpg'),
    Name:"Bishop Paulos Mar Gregorios",
    Des:"Co Founder",
  },
  { pic:require('../assets/img/founders/f7.jpg'),
    Name:"Dr. Manuel A. Thangaraj",
    Des:"(1973-1975)",
  }
];

export default function Founders() {
  return (
    <div className='container' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>Founders - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Founders - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Founders - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/Founders" />
    </Helmet>

       <div className='section-title'>
        <h2> Founders </h2>
      </div>
   <Row>
  
    {founders.map((item, index)=>
     <Col md={3}> <div className='p-2'>
    <Card className='founder-cardlayout' style={{ width: '18rem', padding:"5px", height:"400px" }}>
      <Card.Img variant="top" src={item.pic} />
      <Card.Body>
        <Card.Title>{item.Name}</Card.Title>
        <Card.Text>
        {item.Des}
        </Card.Text>
        
      </Card.Body>
    </Card> </div> </Col>
    )}
   
   </Row>
       
   
   
   



    </div>
  )
}
