// components/PhotoGallery.js

import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import './photostyle.css';
const config = require('../../config.json');

const PhotoGallery = ({ galleryTitle, images, projectDate }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(null);
  
// Automatic image transition (carousel effect)
useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [images.length]);


  // Open modal with clicked image
  const handleImageClick = (index) => {
    setModalImageIndex(index);
    setIsModalOpen(true);
  };

 // Close modal
 const closeModal = () => {
    setIsModalOpen(false);
    setModalImageIndex(null);
  };

  // Navigate to the previous image in modal
  const prevImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

// Navigate to the next image in modal
const nextImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };


  return (
    <div className="gallery-widget">
      <h2 className="gallery-title">{galleryTitle}</h2>
      <p className="project-info">
  <span className="project-date">Date: {projectDate}</span>
  <span className="project-photos">
    <time dateTime="2022-01-01">Photos: {images.length}</time>
  </span>
</p>
      <div className="gallery-container">
        {/* Image that is currently shown in the widget */}
        <motion.div
          className="gallery-item"
          key={currentIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <img
            src={`${config.s3.ImageinvokeURL}${images[currentIndex]}`}
            alt={`gallery image ${currentIndex}`}
            className="gallery-image"
            onClick={() => handleImageClick(currentIndex)}
          />
        </motion.div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={`${config.s3.ImageinvokeURL}${images[modalImageIndex]}`}
              alt={`modal image ${modalImageIndex}`}
              className="modal-image"
            />
            <div className="modal-nav">
              <button className="modal-nav-btn" onClick={prevImage}>
                &#8592; Prev
              </button>
              <button className="modal-nav-btn" onClick={nextImage}>
                Next &#8594;
              </button>
            </div>
            <button className="modal-close-btn" onClick={closeModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
