import React, { useEffect, useState } from 'react';
//import { VJTodayList } from '../../ProData';
import { Modal } from "react-bootstrap";
import PDFViewer from '../../components/PDFViewer';
import axios from 'axios';
//import PublicationHeader from './PublicationHeader';
import {Helmet} from "react-helmet-async";
import Preloader from '../../components/Preloader/Preloader';
//import { useSnackbar } from 'notistack';
import bookImage from '../../assets/img/cat1.png';

const config = require('../../config.json');

export default function AiacheJournal() {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedItem, setSeletedItem] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
 // const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPublicationList();
  }, [])

  const fetchPublicationList =async()=>{
    try{
        setLoaded(false);
    const fetchOption="PubCategory";
    const fetchKey="Active";
    const fetchCategory="New Frontier";
    await axios.get(`${config.api.invokeURL}/dashboard/publications?fetchOption=${fetchOption}&fetchKey=${fetchKey}&fetchCategory=${fetchCategory}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log("Response : ", response);
        const tempRes=response.data.Items;
        tempRes.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));
        //console.log("tempRes : ", tempRes);
        setSearchResult(tempRes);
       // enqueueSnackbar(response.data.Items.length + " - active records found in VJToday",{variant:'success'})
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        //console.log(error.message);
       // enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}) 
        setLoaded(true);
        window.scrollTo(0,0); 
    })
}catch(error){
   // setDispMsg(error.message);
   // enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    setLoaded(true);
    window.scrollTo(0,0); 
}
setLoaded(true);
}



  const toggle = () => {
    //console.log("clicked");
      setCollapsed(!collapsed);
  }

  const handlePDFViewer=async(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);
    setSeletedItem(item);
    setLoaded(false);
    try{
      const fetchFile=item.id+".pdf";
      const bucketName='docs.aiache.co.in';
      const fetchPath='pdfs/report/'+item.id+".pdf"
      await axios.get(`${config.api.invokeURL}/dashboard/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}&filePath=${fetchPath}`,{
          headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
          }}, 
      ).then(async(response)=>{
          //console.log("Response : ", response);
          await setPdfFile(response.data.uploadURL);
          //setShowPDF(true); 
          setCollapsed(!collapsed);
          setLoaded(true);
      }).catch((error)=>{
          //console.log("Error1 : ", error);
          setLoaded(true);
      })  
      
    }catch(error){
      //console.log("Error2 : ", error);
      setLoaded(true);
  }
  }

  const handleImageError = (event) => {
    event.target.src = bookImage;
  };


  return (
  <>
      <Helmet>
      <title>New Frontier Education - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="New Frontier Education - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="New Frontier Education - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/frontiers" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
    {/*<PublicationHeader />
    <FaceView />
    <NewFaceView />*/}
   <Modal  fullscreen show={!collapsed} onHide={toggle}>
    <Modal.Header
      closeButton
      >
        {selectedItem.issueMonth}  {selectedItem.issueYear} ( {selectedItem.issue} )
        </Modal.Header>
    <Modal.Body>
      <PDFViewer pdfFile={pdfFile}/>
    </Modal.Body>
    </Modal>

    <div className='container'>
    <div className="heading_container pt-3">
        <div className='section-title'>
        <h2>New Frontier Education </h2>
      </div>
        </div>

      <div className="row g-5 justify-content-center">
        {searchResult.map((item, index)=>
        <div key={index} className=" col-xl-2 wow fadeIn " data-wow-delay=".3s">
        <div className="blog-item position-relative bg-light rounded d-flex flex-column h-100">
            <img src={`${config.s3.pubPoster}${item.id}${".jpeg"}`} className=" rounded-top zoom"  style={{ width:"100%", height:"10rem" }} alt=""  onError={handleImageError}/>
            {/*<span className="position-absolute px-4 py-3  bg-dark-main text-white rounded" style={{top: "-28px", right: "0px"}}>{item.bookPublishYear}</span>*/}
            <div className="text-center px-4 py-2 border bg-dark-main rounded-bottom " onClick={event=>handlePDFViewer(event, item)} style={{cursor:"pointer"}}>
            <strong className="text-dark-main"> {item.issueMonth}  {item.issueYear} ( {item.issue} )</strong>
            </div>
        </div>
    </div>
)}
        </div>
        {/*<div key={index} className="col-lg-6 col-xl-3 wow fadeIn" data-wow-delay=".3s">
              <div className="card blog-icon btn btn-secondary px-3 rounded-pill" onClick={event=>handlePDFViewer(event, item)}> 
              <a href="#" className="btn text-primary">{item.issueMonth}  {item.issueYear} ( {item.issue} )</a></div>
                {/*<div className="blog-item position-relative bg-light rounded">
                    {/*<img src={item.poster} className="img-fluid w-100 rounded-top" alt="" style={{width:"80px", height:"300px"}} />*/}
                {/*</div>
            </div>*/}
    </div>
  </>
  )
}
