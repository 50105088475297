import React from 'react';
import Table from 'react-bootstrap/Table';
//import pic1 from '../assets/img/offbearers/1.jpg';
//import pic2 from '../assets/img/offbearers/2.jpg';
import pic3 from '../assets/img/offbearers/3.jpg';
import pic4 from '../assets/img/offbearers/4.jpg';
import pic5 from '../assets/img/offbearers/5.jpg';
//import pic6 from '../assets/img/offbearers/6.jpg';
import pic7 from '../assets/img/offbearers/7.jpg';
//import pic8 from '../assets/img/offbearers/8.jpg';
//import pic9 from '../assets/img/offbearers/9.jpg';
import pic10 from '../assets/img/offbearers/10.jpg';
//import pic11 from '../assets/img/offbearers/11.jpg';
//import pic12 from '../assets/img/offbearers/12.jpg';
//import pic13 from '../assets/img/offbearers/13.jpg';
//import pic14 from '../assets/img/offbearers/14.jpg';
//import pic15 from '../assets/img/offbearers/15.jpg';
import pic16 from '../assets/img/offbearers/16.jpg';
import avatar from '../assets/img/offbearers/avatar.jpg';
import { Helmet } from 'react-helmet-async';

export default function OfficeBearers() {
  return (
    <div className='container' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>office Bearers - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Office Bearers - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Office Bearers - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/Office-Bearers" />
    </Helmet>

       <div className='section-title'>
        <h2> Executive Board </h2>
      </div>
         <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          
          <th>Profile Pic</th>
          <th>Name</th>
          <th>Designation</th>
        </tr>
      </thead>
      <tbody >
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic7} alt="" /></td>
          <td>Rev. Sr. M. Rashmi A.C.</td>
          <td><p>President, AIACHE & Principal, Patna Women’s College, Patna
          </p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic10} alt="" /></td>
          <td>Rev. Dr. Praveen Martis, SJ</td>
          <td>Vice President, AIACHE & Vice Chancellor, St. Aloysius (Deemed to be University), Mangalore
          </td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic16} alt="" /></td>
          <td>Dr. Paul Wilson</td>
          <td><p>Vice President, AIACHE & Principal, Madras Christian College, Chennai</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic4} alt="" /></td>
          <td>Rev Dr Xavier Vedam SJ</td>
          <td>General Secretary, AIACHE, Ex-Officio</td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic5} alt="" /></td>
          <td>Dr Chinkhanlun Guite</td>
          <td><p>Treasurer, AIACHE & Bursar, St. Stephen's College, Delhi</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr. Sr. Shiny K.P., 
          </td>
          <td>Board Member, AIACHE & JMJ College for Women (Autonomous), Tenali</td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Rev. Dr. Charles Lasrado SJ</td>
          <td><p>Board Member, AIACHE & Principal, St. Joseph’s College of Commerce (Autonomous), Bengaluru
          (Autonomous), Bailey Road, Patna</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Fr. Dr. Jestin K Kuriakose</td>
          <td><p>Board Member, AIACHE & Principal, Nirmala College Muvattupuzha (Autonomous), Kerala</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr. Letha P Cheriyan</td>
          <td>Board Member, AIACHE & Principal, Mar Thoma College for Women, Perumbavoor , Kerala
          </td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Rev. Dr A. Louis Arockiaraj SJ</td>
          <td><p>Board Member, AIACHE & Principal, Loyola College, Chennai </p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr.V. Regina</td>
          <td>Board Member, AIACHE & Principal, Bishop Newbegin College of Education Chennai
          </td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr. Fr. Jesu Ben Anton Rose</td>
          <td><p>Board Member, AIACHE & Principal, St. Aloysius College (Autonomous), Jabalpur</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Prof. Shailendra Pratap Singh</td>
          <td>Board Member, AIACHE & Principal, St. John's College, Agra
          </td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={pic3} alt="" /></td>
          <td>Dr. Madhumanjari Mandal</td>
          <td><p>Board Member, AIACHE & Principal, Scottish Church College, Kolkata</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr. Sr. A. Nirmala</td>
          <td><p>Board Member, AIACHE & Principal, Loreto College, Kolkata</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Dr. Rudolf Manih</td>
          <td><p>Ex-Officio Member, AIACHE & Principal of UCC College, Shillong</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Rev. Dr. S. Mariadoss SJ</td>
          <td><p>Ex-Officio Member, AIACHE & ST. JOSEPH'S COLLEGE(AUTONOMOUS),. TIRUCHIRAPPALLI</p></td>
          
        </tr>
        <tr>
          <td><img className='img-fluid' style={{width:"120px"}} src={avatar} alt="" /></td>
          <td>Rev Fr Sebastian Karottupurum SDB</td>
          <td><p>Ex-Officio Member, AIACHE & Principal, Don Bosco, Maram, Manipur</p></td>
          
        </tr>
       
      </tbody>
    </Table>
    </div>
  )
}
