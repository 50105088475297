import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function AsiaNetwork() {
  return (
    <div className='container p-3'>
          <div className="heading_container pt-3">
        <div className='section-title'>
        <h2>ASIA Network </h2>
      </div>
        </div>        
        <Tabs
      defaultActiveKey="intro"
      id="fill"
      className="mb-3"
      color='dark'
      fill
      variant="pills"
    >
      <Tab eventKey="intro" title="INTRO">
       
       <p style={{fontSize:"22px"}}>
    <b>    Report on the Progress of MoU between AIACHE-ASIANetwork </b> <br/> </p> 
    <p style={{textAlign:"justify", lineHeight:"30px"}}>
As AIACHE ventures for new avenues to strengthen teaching and research, one such milestone is the signing of MoU between between AIACHE and ASIANetwork. 
ASIANetwork is a consortium of 171 colleges and universities of the liberal arts across United States which aims to perform wider academic mission. In a view to enhance academic standards and strengthen collaborations, AIACHE had initiated the process of venturing into a MoU with ASIANetwork in June 2020. 
After learning the possibilities of direct and indirect collaborations, the MoU was drafted by the AIACHE-ASIANetwork Coordination Committee with Rev. Fr. V. Xavier Vedham, General Secretary, AIACHE as the chair person. Finally, through several online deliberations, the MoU was drafted and was signed on 1st February 2021, by then signatories, Rev. Fr. Xavier Vedam SJ, General Secretary, AIACHE, Rev, Fr. George Thadathil SDB, President, AIACHE. Dr. Gary Decoker, Executive Director, ASIANetwork and Dr. Dan Choffnes, Chairperson, Board of Directors, ASIANetwork, 
The scope of MoU includes Exchange Programme for Student/Faculty/Administrative Staff, Organization of Periodical Faculty Development Programmes on specific areas,  Educational and Cultural Tours  for students, faculty & administrative staff, Undertaking Academic Programmes with credit transfer, Creation and sharing of e-resources and conduct of certified on-line courses, Organizing Conferences/Seminars/Workshops on location or virtually, Undertaking Collaborative Research Projects, Publications of Journals, Books and E-publications and facilitating the Ranking of the member institutions. 
Ever since the signing of MoU there has been many initiatives towards establishing collaborations between AIACHE and ASIANetwork. 
Shrunk by the waves of Corona, MoU was formally inaugurated during the prior Triennial Conference on 27th December 2021.
<br/>
<b> The following are the activities under AIACHE-ASIANetwork Collborations till date: </b><br/>
(i)	The first collaborative activity was marked even before the formal signing of MoU, wherein a group of 10 ASIANetwork members visited Salesian College, Sonada, Siliguri, West Bengal and other parts of North East India, as a part of ANFEP (ASIANetwork Faculty Enhancement Programme) in July 2018.<br/>
(ii)	A book on “American Popular Culture”, written by ASIANetwork members, has been published by the Salesian Press, Sonada, Siliguri, in December 2021.<br/>
(iii)	An online Student Interaction Programme between three colleges of AIACHE (Holy Cross College, Tiruchirappalli, Tamil Nadu, Bishop Heber College, Tiruchirappalli, Tamil Nadu and Salesian College, Siliguri, West Bengal) and the University of Puget Sound, Washington, USA, which was sketched out during a small group online interaction. Dr. Mary Jeyanthi from Holy Cross College, Trichy, Dr. Anne from Bishop Heber College, Dr. ______ from Salesian College, Siliguri and Dr. Priti Joshi from University of Puget Sound were the coordinators. The students had fruitful deliberations in spite of the time difference, exchanging ideas on Indian Literature and American Literature
<br/>
(iv)	After the orientation, a concrete database of experts and nodal members for ASIANetwork activities from member institutes were collected via Google Forms from the Principals of the AIACHE Membet institutes.
<br/>
(v)	A mega online meet was conducted on 29.09.2022 in 9 breakout rooms based on the discipline, where 7 ASIANetwork members interacted with 300+ faculty members of different institutes. This was a great kick starting event making aware of the possible collaborations.
<br/>
(vi)	There were several online meets among the AIACHE and ASIANetwork members in the presence of Dr. Dan Choffnes, Rev. Fr. Xavier Vedam, Rev, Fr. George Thadathil, Dr. A. Josephine Prabha, Coordinator of the activities.
<br/>
(vii)	Dr. Lisa Trivedi, is a Professor of History at Hamilton College, Clinton, New York, active member of ASIANetwork and served as Board of Directors from 2007-2010. She is a social and cultural historian of modern South Asia who has published on the histories of nationalism, On 10.02.23, Dr. Lisa Trivedi, having come to India for her research purpose met Rev.Dr. Xavier Vedam, General Secretary of AIACHE at Delhi along with xxxx, Professor of History, Jesus Mary College, Delhi. The discussions paved way for an online meet on 25.02.23. 
<br/>
(viii)	A special lecture on ASIANetwork Collaborationa and the Research Proposals in Humanities and Social Sciences was addressed by Dr. Lisa Trivedi,Professor of History, Hamilton College, Clinton, New York, USA  on 25th February 2023, from 3.30 p.m. to 5 p.m. There were 111 participants all over India connected online and interacted with the resource person.
<br/>
(ix)	The following AIACHE members participated in the Annual Conference of ASIANetwork which was 
held on 16th April 2023 to focus how ‘Asian Studies’ is transformed by centering South Asia.<br/>
1. Fr George Thadathil, Former President, AIACHE and Principal, Salesian College, Siliguri <br/>
2. Dr. A. Josephine Prabha, AIACHE ASIANetwork Coordinator and Associate Dean of Sciences, Bishop Heber College, Tiruchirappalli<br/>
3. Dr M. Gautaman, Head, Department of Sociology, Loyola College, Chennai<br/>
4. Dr M. Mary Jayanthi, Vice-Principal and Associate Professor of English, Holy Cross College, Tiruchirappalli<br/>
They highlighted the benefits of well-established relationship between AIACHE and ASIANetwork. Each of them briefed about the possible collaborations in the context of diverse culture, geographical locations, heritage and it was insisted that there are many collaborative projects to work on languages, history and social work. It paved way for great sharing and set a stage for future activities.
<br/>
(x)	Several small group meets and two planning meetings with the ASIANetwork Members and AIACHE members on 11.05.23 and  12.07.23 has led to the germination of FDP series facilitated purely by ASIANetwork Members who visited India in October 2023. The AIACHE members present in the zoom meet on 12.07.23 were Rev. Dr. Xavier Vedam, General Secretary of AIACHE, Rev, Fr. George Thadathil, Former President, AIACHE, Dr. John Peter, Director, Loyola International Academic Collaboration, Chennai, Dr. Phoebe Angus, Assistant Professor of English, Madras Christian College, Chennai, the coordinators of FDP series in different locations Dr. Rama, Loyola Academy, Andhra Pradesh, Dr. Leena, St. Teresa’s College, Kerela and Dr. A. Josephine Prabha, Bishop Heber College, Tiruchirappalli along with the 3 facilators from ASIANetwork.
<br/>
(xi)	A series of 4 International Faculty Development Programme FETS (Faculty Excellence in Teaching and Scholarship) 2023 was convened in 4 locations in India, namely, Hyderabad (18-19 Oct 2023), Tiruchirappalli (23-24 Oct 2023) and Bangalore (27-28 Oct 2023) and Cochin (30-31 Oct 2023) between 18th and 31st October  2023. The Asian Network Members, Dr. Glenn Shive, Historian of Asia, associated with United Board for Christian Higher Education In Asia for more than 25 years, Prof. Spencer Benson, Trainer and Expert of Biological Sciences and Prof. Melissa Fitch, Distinguished Professor, Latin American Cultural Studies, University of Arizona, Fulbright Scholar, JNU, Delhi were the Facilitators for the two day FDP Series.
<br/>
The topics covered were:
•	What is meaningful learning in today’s world?<br/>
•	Motivating Student in a World Filled with Distractions and Disruptors<br/>
•	Skills students will need for life and careers in the 21st century: Meeting Student and Society Expectations<br/>
•	Issues for Excellence in Teaching and Learning in the 21st Century <br/>
•	Challenges for 21st Century Teaching and Learning (OBE, UBD, AI, Assessment – for learning not for grading)<br/>
•	Scholarship avenues across disciplines, institutions, nations<br/>
<br/>
(xii)	In addition, the workshop included open space for discussion and an Asia Network presentation(s) online from USA on the evening of first day by Professors from USA, which laid a strong base to identify points of interest and build collaborative research activities and student & staff exchange programmes. 
The members who interact online were:<br/>
(a)	Dr. Sucharita Sinha Mukherji, Professor of Economics, College of Saint Benedict and Saint John's University
<br/>
(b)	Prof. Kingshuk Mukherjee, Associate Professor, Global Business Leadership, College of Saint Benedict and Saint John's University<br/>
( c) Dr. Monica Kaniamattam, Assistant Professor of Speech-Language Pathology
Moravian University<br/>

(d)	Dr. Aswini Pai, Associate Professor of Biology,St. Lawrence University<br/>
( e)Terri McNichol, Ren Associates <br/>

 No doubt, the visit of ASIANetwork members and online interactions served to be a great stepping stone to venture into new discipline specific collaborative activities
Future Plans:<br/>
(a)	There are 18 possible collaborative activities based on Podcast, conduct of Workshops and seminars and exchange programmes. staff and student exchange programmes and co-teaching in environmental sociology. Though we have financial challenge unable to support many exchange programmes, AIACHE suggests that interested institutions may come forward to sponsor one faculty for a staff exchange programme to USA to collaborate and work with ASIANetwork members.
<br/>
(b)	FETS 2025 is proposed to be held on 4th and 5th of April 2025 in Don Bosco Institute for Development and Leadership (DBIDL), Dimapur, Nagaland.
<br/>
(c)	The website of AIACHE to be updated with an exclusive page for ASIANetowork collaborative activities which is the need of the hour to strengthen the activities with ease of linking both the domains.
<br/>
No doubt ASIANetwork collaborations are a boon to us and I trust that the member institutions will make the best our of it in the days to come, which will surely support the global level of teaching and research which is the buzz word for all higher education institutions.


       </p>
      </Tab>
      <Tab eventKey="Content" title="COLLABORATION">
        <p style={{textAlign:"justify", lineHeight:"30px"}}>
       <b>  A. Podcast Series<br/></b>
1. Dr. William "Chip" Gruen, Muhlenberg College (Pennsylvania) to facilitate a podcast series on religious pluralism, involving students from Muhlenberg and an Indian college/s.
<br/>
<b> B. On-line / in-person Conference<br/></b>
2. Dr. Jarrod Brown, Berea College (Kentucky) - an online or in-person conference on South Asian philosophy or historical connections of South and Southeast Asia.
<br/>
3. Dr. Movindri Reddy, Occidental College (California), is interested in organizing a joint workshop/conference on the Indian Diaspora.
<br/>
4. Monica Kaniamattam is an Assistant Professor of Speech-Language Pathology Moravian University, Pennsylvania, USA, is interested with regard to speech therapy, understanding the communication styles of Asian.
<br/>
<b> C. Student Exchange Program</b>
<br/>
5. Dr. Brian Caton, Luther, College (Iowa) looks for a partner institution in India to bring U.S. students for a 3.5-week January study abroad course.
<br/>
6. Dr. Michael Hirsch, Huston-Tillotson University (Texas) is looking for Indian partner institutions for faculty/student travel and exchange.
<br/>
7. Dr. K. Kingshuk Mukherjee Global Business Leadership College of Saint Benedict and Saint John’s University, USA and Dr. Sucharita Sinha Mukherjee, Professor of Economics College of Saint Benedict and Saint John’s University, USA, are interested in marketing and management
<br/>
8. Dr. Steven Day, Benedictine University near Chicago reviewed the proposal for “immersive service and experiential learning opportunities in South India” and would be interested in potentially recruiting students for such an opportunity.
<br/>
<b> D. Co-teaching/ Co-writing</b>
<br/>
9. Dr. Yudit Greenberg, Rollins College (Florida), is interested in finding a co-teaching/writing partner in the area of comparative religion. Her scholarly areas are the Hebrew Bible, Jewish Life and Thought, Women and Religion, Hebrew.
<br/>
10. Dr. Michael Hirsch, Huston-Tillotson University (Texas) is interested in co-editing a book on Indo-Pak issues bringing together scholars from both nations.
<br/>
<b> E. Research Collaborations</b>
<br/>
11. Dr. Jarrod Brown, Berea College (Kentucky)  is interested to do research related to environmental ethics, South Asian philosophy, and Islam in India.
<br/>
12. Dr. Soundarya Chidambaram, Bucknell University (Pennsylvania),  is interested in connecting with Indian students who can interview women in urban slums in Tamil Nadu for an understanding of civil society, collective action, public goods provision, and political actions. Also seeking students proficient in machine learning to help content analyze political messages on social media.
<br/>
13. Dr. Yudit Greenberg, Rollins College (Florida) seeking a host institution for a future research/teaching residency in India.
<br/>
14. Dr. Gerise Herndon, Nebraska Wesleyan University (Nebraska), is planning a possible 2-week visit in India, autumn 2024. Looking for a host institution to carry out research on third gender identities.
<br/>
15. Dr. Rehanna Kheshgi, St. Olaf College (Minnesota), is looking for an institutional partner in Assam who can facilitate and join in work with NGOs involving local scholars and activists.
<br/>
16. Dr. David Martin, Davidson College (North Carolina), is interested in helping researchers apply economic analysis to their environmental policy or biodiversity protection policy research topics.
<br/>
17. Dr. Movindri Reddy, Occidental College (California) is interested in collaborative research in nationalism and citizenship, transnational social movements, and diasporic identity.
<br/>
18. Dr. Lisa Trivedi, is a Professor of History at Hamilton College, Clinton, New York is interested in social and cultural historian of modern South Asia
<br/>
19. Dr. Aswini Pai, Associate Professor & Co-Chair of Biology at St. Lawrence University, New York, USA is interested in the science domains

        </p>
      </Tab>
     
    </Tabs>

    </div>
  )
}
