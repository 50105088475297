import React from 'react';
import {Row, Col} from 'react-bootstrap';
import about from '../assets/img/about.jpg';
import about2 from '../assets/img/about-2.jpg';
import theo from '../assets/img/team/frtheo.png';
import karuna from '../assets/img/team/srkaruna.jpg';
import marie from '../assets/img/team/marie.jpg';
import mani from '../assets/img/team/mani.jpg';
import VissionMission from './VissionMission';
import { Helmet } from 'react-helmet-async';

export default function AboutPageMain() {
  return (
    <div >
      <Helmet>
      <title>About Us - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="About Us - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="About Us - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/about" />
    </Helmet>


<section id="about" className="about section" >
     

      <div className="container section-title" data-aos="fade-up">
        <h2>About Us<br/></h2>
        <p>AIACHE - The All India Association for Christian Higher Education</p>
      </div>

      <div className="container">

        <div className="row gy-4">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3>AIACHE</h3>
            <img src={about} className="img-fluid rounded-4 mb-4" alt=""/>
             </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
            <div className="content ps-0 ps-lg-5">
              
             
              <p style={{textAlign:"justify"}}>
              
              The All India Association for Christian Higher Education (AIACHE) is an Ecumenical professional Association for 500+ Christian Higher Education colleges in India, founded in 1967. AIACHE aimsat the promotion of quality in Higher Education, preservation of human and social values and production of character formation, value inculcation and co-operative skill training for a better altruistic society.
              Our professors are from all around the country and represent a wide range of academic fields. Faculty members at our member institutions have interests and experience in a wide range of fields, including the arts and humanities, social sciences, and natural sciences. Their Pedagogy, Andragogy and Heutagogy are deep-rooted fromteacher-centric lecture methods and learner-centric hybrid pedagogy to active teaching, training solutions, online education, and digital technology. Our faculty supports immersive and hands-on learning via social sciences, religion, culture, and the arts as well as through scientific inquiry.</p>
         

              <div className="position-relative mt-4">
                <img src={about2} className="img-fluid rounded-4" alt=""/>
                <a href="https://www.youtube.com/watch?v=2uRO1Ti3xVY&t=3s" className="glightbox pulsating-play-btn"></a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </section>  
    <div className='container'>

<Row>
  <Col md={6}>
  <VissionMission/>
  </Col>
  <Col md={6}>
  <div className="icon-boxes position-relative" data-aos="fade-up" data-aos-delay="200">
        <div className="container position-relative">
          <div className="row gy-4 mt-5">

            <div className="col-xl-6 col-md-6">
              <div className="icon-box">
              {/*  <div className="icon"><i className="bi bi-easel"></i></div> */}
              <img className='img-fluid rounded' src={theo} alt="team" />
                <h4 className="title p-2"><a href="" className="stretched-link">Fr. Theo Mathias</a></h4>
              </div>
            </div>

            <div className="col-xl-6 col-md-6">
              <div className="icon-box">
              <img className='img-fluid rounded' src={karuna} alt="team" />
                <h4 className="title p-2"><a href="" className="stretched-link">Sr. Karuna Mary Braganza</a></h4>
              </div>
            </div>

            <div className="col-xl-6 col-md-6">
              <div className="icon-box">
              <img className='img-fluid rounded' src={marie} alt="team" />
                <h4 className="title p-2"><a href="" className="stretched-link">Prof. Marie Correa</a></h4>
              </div>
            </div> 

            <div className="col-xl-6 col-md-6">
              <div className="icon-box">
              <img className='img-fluid rounded' src={mani} alt="team" />
                <h4 className="title p-2"><a href="" className="stretched-link">Dr. Mani Jacob</a></h4>
              </div>
            </div> 

            
          </div>
        </div>
      </div>
  </Col>
</Row>
    

      



    </div>

         

    </div>
  )
}
