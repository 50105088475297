import React, { Component } from 'react';
import assetnew from './assets/img/cta-bg.jpg';
import 'react-alice-carousel/lib/alice-carousel.css';
import team1 from './assets/img/team/ob5.png';
import team2 from './assets/img/team/ob4.png';
import team3 from './assets/img/offbearers/7.jpg';
import team4 from './assets/img/offbearers/10.jpg';
import Dr_Madhu from './assets/img/offbearers/16.jpg';
import CaroselMultiView from './pages/CaroselMultiView';
import GalleryView from './pages/GalleryView';
import AboutPageMain from './pages/AboutPageMain';
import GalleryMain from './pages/gallery/GalleryMain';


export default class Home extends Component {
  render() {
    return (
      
      <div >
        
        <body className="index-page">

  <main className="main">

  
<div >
  <CaroselMultiView />
</div>

          
      



 

  
  <AboutPageMain/>

   





    
    <section id="call-to-action" className="call-to-action section dark-background">

      <div className="container">
        <img src={assetnew} alt=""/>
        <div className="content row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-xl-10">
            <div className="text-center">
              <a href="https://www.youtube.com/watch?v=2uRO1Ti3xVY&t=3s" className="glightbox play-btn"></a>
              <h3>Call To Action</h3>
              <p></p>
              <a className="cta-btn" href="#">Call To Action</a>
            </div>
          </div>
        </div>
      </div>

    </section>
   

   
    <section id="team" className="team section">

 
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Team</h2>
        <p></p>
      </div> 

      <div className="container">

        <div className="row gy-4">

          <div className="col-xl-4 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
              <img src={team1} className="img-fluid" alt="" />
              <h4>REV. DR. XAVIER VEDAM S.J</h4>
              <span>General Secretary</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div> 

          <div className="col-xl-4 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="member">
              <img src={team2} className="img-fluid" alt="" />
              <h4>Dr. CHINKHANLUN GUITE</h4>
              <span>Treasurer</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div> 

          <div className="col-xl-4 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="300">
            <div className="member">
              <img src={team3} className="img-fluid" alt="" />
              <h4>Rev. Sr. M. Rashmi A.C.</h4>
              <span>President</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div> 

          <div className="col-xl-4 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="400">
            <div className="member">
              <img src={team4} className="img-fluid" alt="" />
              <h4>Rev. Dr. Praveen Martis, S J</h4>
              <span>Vice President</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div> 

          <div className="col-xl-4 col-md-6 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="400">
            <div className="member">
              <img src={Dr_Madhu} className="img-fluid" alt="" />
              <h4>Dr. Paul Wilson</h4>
              <span>Vice President</span>
              <div className="social">
                <a href=""><i className="bi bi-twitter-x"></i></a>
                <a href=""><i className="bi bi-facebook"></i></a>
                <a href=""><i className="bi bi-instagram"></i></a>
                <a href=""><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div> 

        </div>

      </div>

    </section>

  

  {/*} <GalleryView/>*/}
  <GalleryMain />

  
    <section id="contact" className="contact section">

 
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p></p>
      </div> 

      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gx-lg-0 gy-4">

          <div className="col-lg-4">
            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
<p>All India Association for Christian Higher Education</p>
<p>Ecumenical Centre</p>
<p>39, Institutional Area</p>
<p>D-Block, Janakpuri</p>
<p>New Delhi – 110 058, India</p>
                </div>
              </div>

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>Call Us</h3>
                  <p>+91-94447 61101</p>
                </div>
              </div>

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Us</h3>
                  <p> aiache2011@gmail.com</p>
                </div>
              </div> 

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                <i className="bi bi-clock flex-shrink-0"></i>
                <div>
                  <h3>Open Hours:</h3>
                  <p>Mon-Sat: 09.00 AM - 06.00 PM</p>
                </div>
              </div> 

            </div>

          </div>

          <div className="col-lg-8">
            <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade" data-aos-delay="100">
              <div className="row gy-4">

                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required=""/>
                </div>

                <div className="col-md-6 ">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required=""/>
                </div>

                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required=""/>
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="8" placeholder="Message" required=""></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>

                  <button type="submit">Send Message</button>
                </div>

              </div>
            </form>
          </div>

        </div>

      </div>

    </section>

  </main>

  <footer id="footer" className="footer footer-background">

    <div className="container footer-top">
      <div className="row gy-4">
        <div className="col-lg-5 col-md-12 footer-about">
          <a href="index.html" className="logo d-flex align-items-center">
            <span className="sitename">AIACHE</span>
          </a>
          <p>The All India Association for Christian Higher Education (AIACHE) is an Ecumenical professional Association for 500+ Christian Higher Education colleges in India, founded in 1967.</p>
          <div className="social-links d-flex mt-4">
            <a href=""><i className="bi bi-twitter-x"></i></a>
            <a href=""><i className="bi bi-facebook"></i></a>
            <a href=""><i className="bi bi-instagram"></i></a>
            <a href=""><i className="bi bi-linkedin"></i></a>
          </div>
        </div>

        <div className="col-lg-2 col-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">About us</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Terms of service</a></li>
            <li><a href="#">Privacy policy</a></li>
          </ul>
        </div>
{/*}
        <div className="col-lg-2 col-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><a href="#">Web Design</a></li>
            <li><a href="#">Web Development</a></li>
            <li><a href="#">Product Management</a></li>
            <li><a href="#">Marketing</a></li>
            <li><a href="#">Graphic Design</a></li>
          </ul>
        </div>
*/}
        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          <h4>Contact Us</h4>
<p>All India Association for Christian Higher Education,</p>
<p>Ecumenical Centre</p>
<p>39, Institutional Area</p>
<p>D-Block, Janakpuri</p>
<p>New Delhi – 110 058, India</p>
          <p className="mt-4"><strong>Phone:</strong> <span>+91-9444761101</span></p>
          <p><strong>Email:</strong> <span>aiache2011@gmail.com</span></p>
        </div>

      </div>
    </div>

    <div className="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong className="px-1 sitename">Idntica</strong> <br/></p>
      <div className="credits">
       
      <span>All Rights Reserved</span>
      </div>
    </div>

  </footer>


  <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>




</body>
      </div>
    )
  }
}




