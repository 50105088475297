import React from 'react';
import Table from 'react-bootstrap/Table';
//import Button from 'react-bootstrap/Button';
import life from '../assets/pdfs/Life Membership Application form.pdf';
import annual from '../assets/pdfs/Annual Membership Application form.pdf';
import friend from '../assets/pdfs/Individual Friend Application form.pdf';
import { Helmet } from 'react-helmet-async';

export default function Applications() {
   
    const onLifeClick = () => {
        const pdfUrl = require('../assets/pdfs/Life Membership Application form.pdf');
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Life Membership Application form.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const onAnnualClick = () => {
        const pdfUrl = require('../assets/pdfs/Annual Membership Application form.pdf');
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Annual Membership Application form.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const onFriendClick = () => {
        const pdfUrl = require('../assets/pdfs/Individual Friend Application form.pdf');
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Individual Friend Application form.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
  return (
    <div className='container' style={{paddingTop:"3%"}} >
    <Helmet>
      <title>Application - AIACHE - All India Association for Christian Higher Education - Delhi</title>
      <meta name="description" content="Application - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <meta name="keywords" content="Application - AIACHE - All India Association for Christian Higher Education - Delhi" />
      <link rel="canonical" href="/application" />
    </Helmet>

        <div className='section-title'>
            <h2>Applications</h2>
        </div>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Description</th>
          <th>Application Download</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Life Membership Application form</td>
          <td><a href={life}>View the Application </a><button  onClick={onLifeClick}>
                    Download
                </button></td>
      
        </tr>
        <tr>
          <td>2</td>
          <td>Annual Membership Application form</td>
          <td><a href={annual}>View the Application</a><button  onClick={onAnnualClick}>
                    Download
                </button></td>
         
        </tr>
        <tr>
          <td>3</td>
          <td >Individual Friend's Application form</td>
          <td><a href={friend}>View the Application</a><button  onClick={onFriendClick}>
                    Download
                </button></td>
          
        </tr>
      </tbody>
    </Table>
    </div>
  )
}
