//import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home.js';
import './assets/css/main.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import GalleryView from './pages/GalleryView.js';
//import Navbar from './Navbar.js';
import MembersView from './pages/Members/MembersView.js';
import AboutPageMain from './pages/AboutPageMain.js';
import Presidents from './pages/Presidents.js';
import GenSecrat from './pages/GenSecrat.js';
import OfficeBearers from './pages/OfficeBearers.js';
import Founders from './pages/Founders.js';
//import Vission from './pages/VissionMission.js';
import VissionMission from './pages/VissionMission.js';
import Applications from './pages/Applications.js';
import NavbarView from './NavbarView.js';
//import PhotoGallery from './pages/gallery/PhotoGallery.js';
//import GalleryMain from './pages/gallery/GalleryMain.js';
import ExecutiveMembers from './pages/ExecutiveMembers.js';
import AiacheJournal from './pages/journal/AiacheJournal.js';
import AsiaNetwork from './pages/AsiaNetwork.js';
import PhotoGallery from './pages/gallery/PhotoGallery.js';
import GalleryMain from './pages/gallery/GalleryMain.js';



function App() {
  return (
    <div className="App">
      <NavbarView/>
<div style={{paddingTop:"150px"}}>


   <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/gallery" element={<GalleryView />} />
  <Route path="/members" element={<MembersView />} />
  <Route path="/about" element={<AboutPageMain />} />
  <Route path="/Founders" element={<Founders />} />
  <Route path="/Executive" element={<ExecutiveMembers />} />
  <Route path="/executiveboard" element={<OfficeBearers />} />
  <Route path="/members" element={<MembersView />} />
  <Route path="/Presidents" element={<Presidents />} />
  <Route path="/General-Secrataries" element={<GenSecrat />} />
  <Route path="/vission" element={<VissionMission/>} />
  <Route path='/application' element={<Applications/>} />
  <Route path='/frontiers' element={<AiacheJournal />} />
  <Route path='/asia' element={<AsiaNetwork/>} />
  <Route path="/galleryphoto" element={<GalleryMain />} />

  </Routes>
</div>
 
    </div>
  );
}

export default App;
